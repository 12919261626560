export const CHANGE_RELEASE_DROPDOWN_VERSION_FILTER = 'CHANGE_RELEASE_DROPDOWN_VERSION_FILTER'

export type ChangeReleaseDropdownVersionAction = {
  type: typeof CHANGE_RELEASE_DROPDOWN_VERSION_FILTER,
  value: string
}

const changeReleaseDropdownVersionFilter = (filterText: string): ChangeReleaseDropdownVersionAction => {
  return {
    type: CHANGE_RELEASE_DROPDOWN_VERSION_FILTER,
    value: filterText
  }
}

export default changeReleaseDropdownVersionFilter
