import { ProductConfigurationPastedAction } from '../actions/product_configuration_pasted'

const PRODUCT_CONFIGURATION_JSON_PASTED = 'PRODUCT_CONFIGURATION_JSON_PASTED'

export default function pastedConfigText(text: string = '', action: ProductConfigurationPastedAction) {
  switch (action.type) {
  case PRODUCT_CONFIGURATION_JSON_PASTED:
    return action.value
  default:
    return text
  }
}