import { InfoAlert } from 'pivotal-ui/react/alerts'
import { ListItem, UnorderedList } from 'pivotal-ui/react/lists'
import React from 'react'
import { PlanAlertLevels } from '../../chosen_products/chosen_products_types'
import { Alert } from '../../repositories/upgrade_plan'

export const InfosSection = ({alerts}: { alerts: Alert[] }) => {
  let filteredWarnings = alerts.filter((alert) => {
    return alert.level === PlanAlertLevels.INFO
  })

  if (filteredWarnings.length === 0) return null

  return (
    <div className='mbxl type-sm'>
      <InfoAlert withIcon>
        <UnorderedList>
          The following tiles are incompatible with the other tiles currently installed on your foundation:
          {
            filteredWarnings.map((alert: Alert, index: number) => {
              return (<ListItem key={index}>{alert.message}</ListItem>)
            })
          }
          Follow the phases in the upgrade plan so that your foundation is in a compatible start state.
        </UnorderedList>
      </InfoAlert>
    </div>
  )
}
