import { ChosenProduct } from './chosen_products_types'

export const REMOVE_PRODUCT_FROM_CONFIGURATION_ACTION = 'REMOVE_PRODUCT_FROM_CONFIGURATION'

export type RemoveProductFromConfigurationAction = {
  type: typeof REMOVE_PRODUCT_FROM_CONFIGURATION_ACTION,
  product: string
}

const removeProductFromConfiguration = ({slug}: ChosenProduct): RemoveProductFromConfigurationAction => {
  return {
    type: REMOVE_PRODUCT_FROM_CONFIGURATION_ACTION,
    product: slug
  }
}

export default removeProductFromConfiguration
