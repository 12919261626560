import 'whatwg-fetch'
import './css-imports'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from './stores/store'
import { UpgradePlanRepository } from './repositories/upgrade_plan'
import ProductRepositoryImpl from './repositories/products'
import AnalyticsWrapper from './services/analytics_wrapper'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Docs } from './components/docs'
import { UPServerFetcher } from './services/up_server_fetcher'
import { FoundationRepositoryImpl } from './repositories/foundation'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBan } from '@fortawesome/free-solid-svg-icons'

// Font awesome initializer
library.add(faBan)

const analyticsWrapper = new AnalyticsWrapper(process.env.REACT_APP_GTM_ID || '')

analyticsWrapper.initialize()
analyticsWrapper.firePageView('/')

const upServerFetcher = new UPServerFetcher(process.env.REACT_APP_SERVER_URL || 'http://localhost:8080')
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path={'/docs'} component={Docs}/>
        <Route path={'/'}>
          <App
            analyticsWrapper={analyticsWrapper}
            upgradePlanRepository={new UpgradePlanRepository(upServerFetcher)}
            productRepository={new ProductRepositoryImpl(upServerFetcher)}
            foundationRepository={new FoundationRepositoryImpl(upServerFetcher)}
          />
        </Route>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)
