import React from 'react'

import { connect } from 'react-redux'
import { Autocomplete } from 'pivotal-ui/react/autocomplete'
import { FilteredProduct, productFilterFactory } from '../product_filter'
import { Icon } from 'pivotal-ui/react/iconography'
import { Panel } from 'pivotal-ui/react/panels'
import selectNewProduct from '../chosen_products/select_new_product_action'
import { Product, ProductDetails, ProductRepository } from '../repositories/products'
import { UpgradePortalStore } from '../stores/store'
import AnalyticsWrapper from '../services/analytics_wrapper'

type ProductSelectionMenuProps = {
  analyticsWrapper: AnalyticsWrapper
  products: Product[]
  productFilterFactory: (products: Product[], searchText: string) => FilteredProduct[]
  productRepository: ProductRepository
  selectNewProduct: (product: Product | ProductDetails, productRepository: ProductRepository) => void
  diagnosticReportImported: boolean
}

type ProductSelectionMenuState = {
  value: string
}

class ProductSelectionMenu extends React.Component<ProductSelectionMenuProps, ProductSelectionMenuState> {
  constructor(props: ProductSelectionMenuProps) {
    super(props)
    this.state = {value: ''}
  }

  render() {
    const {
      analyticsWrapper,
      products,
      productFilterFactory,
      productRepository,
      selectNewProduct,
      diagnosticReportImported
    } = this.props

    const onSearch = (text: string, callback: (f: FilteredProduct[]) => void) => {
      const filteredResults = productFilterFactory(products, text)
      callback(filteredResults)
      this.setState({value: text})
    }

    const onInitializeItems = (callback: (f: FilteredProduct[]) => void) => {
      const puiFormattedProducts = products.map(product => ({
        value: product.name,
        product
      }))

      callback(puiFormattedProducts)
    }

    const onPick = (value: FilteredProduct) => {
      if (!value.product) return

      if (diagnosticReportImported) {
        analyticsWrapper.fireEvent('Product Search', 'Click', 'Added Product After Report Import')
      } else {
        analyticsWrapper.fireEvent('Product Search', 'Click', 'Added Product First')
      }

      selectNewProduct(value.product, productRepository)
      this.setState({value: ''})
    }

    return (
      <div>
        <h2 className='inline pbl ptxxl'>Manually Add Tiles</h2>
        <p className='mbs'>Recommended target versions of manually added tiles are compatible with the Operations Manager Target Version selected above.</p>
        <Panel bodyClassName={'display-flex'}>
          <span className={'mrl icon h1'}>
            <Icon src={'add_circle'}/>
          </span>
          <Autocomplete
            className={'flex-1'}
            placeholder={'Search and Add Products'}
            onInitializeItems={onInitializeItems}
            onSearch={onSearch}
            onPick={onPick}
            value={this.state.value}
          />
        </Panel>
      </div>
    )
  }
}

const mapStateToProps = ({products, diagnosticReportImported}: UpgradePortalStore) => {
  return {
    products,
    productFilterFactory,
    diagnosticReportImported
  }
}

const mapDispatchToProps = {
  selectNewProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelectionMenu)
export { ProductSelectionMenu }
