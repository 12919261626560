export class UPServerFetcher {
  private readonly domain: string

  constructor(domain: string) {
    this.domain = domain
  }

  async fetch(request: RequestInfo): Promise<Response> {
    let requestInit: RequestInit = {}

    if (typeof request === 'string') {
      requestInit.headers = new Headers()
    } else {
      requestInit.headers = request.headers
    }

    return fetch(request, requestInit).then(response => {
      return response
    })
  }

  generateUrl(endpoint: string): string {
    return `${this.domain.replace(/\/$/, '')}${endpoint}`
  }
}
