import { LOAD_UPGRADE_PLAN, LoadUpgradePlanAction } from '../actions/load_upgrade_plan'
import { Plan, PlanSupportStatus } from '../repositories/upgrade_plan'

let initialPlan: Plan = {
  phases: [],
  alerts: [],
  foundation: [],
  supportStatus: PlanSupportStatus.OutOfSupport
}

export default function upgradePlanReducer(upgradePlan: Plan = initialPlan, action: LoadUpgradePlanAction) {
  switch (action.type) {
  case LOAD_UPGRADE_PLAN:
    return action.value
  default:
    return upgradePlan
  }
}