import React from 'react'

import Footer from './footer'
import { UpgradePlannerHeader } from './upgrade_planner_header'

class Docs extends React.Component {
  render() {
    return (
      <div>
        <UpgradePlannerHeader/>
        <div className="container paxxl">
          <h1 className="docs-h1">Overview</h1>

          <div className="docs-paragraph">Upgrading VMware Tanzu Platform (formerly named Pivotal Cloud Foundry) foundations
            ensures that they are
            fully-featured and secure. However, mapping out product dependencies and upgrade paths can make upgrading
            platforms with many service tiles very complicated. VMware has created a tool dedicated to reducing the
            time, toil, and complexity required to plan an upgrade.
          </div>

          <div className="docs-paragraph">The Upgrade Planner tool provides reliable upgrade plans for products
            running on VMware Tanzu Platform
            using product metadata found on <a href={'https://network.pivotal.io'}>Tanzu Network</a> and information
            on <a href={'https://docs.pivotal.io'}>VMware Tanzu Docs</a>. The plans include:
          <ul className={'mvn'}>
            <li>Upgrade paths that maintain compatibility with Operations Manager (Ops Manager) and any other
                product dependencies
            </li>
            <li>Links to Release Notes</li>
            <li>End of General Support (EOGS) dates</li>
            <li>Links to Upgrade Checklists</li>
            <li>Links to VMware Tanzu Platform Breaking Changes</li>
            <li>Notable Upgrade Warnings (Upgrade Planner does not include every Breaking Change for product
                releases and should not substitute reading product release notes.)
            </li>
          </ul>
          </div>
          <div className="docs-paragraph">Upgrade Planner is available for use at <a
            href={'https://upgrade-planner.pivotal.io/'}>https://upgrade-planner.pivotal.io</a>.
          </div>
          <div className="docs-paragraph">To upgrade Operations Manager, platform engineers should first upgrade each currently
            installed product tile to the
            minimum version that is certified to run in the version of Operations Manager that is targeted for the upgrade.
          </div>

          <h1 className="docs-h1">Requirements</h1>
          <div className="docs-paragraph">To use Upgrade Planner, you will need Operations Manager v1.8.x or higher.
          </div>

          <h1 className="docs-h1">Getting Started</h1>
          <div className="docs-paragraph">There are two ways to get started using Upgrade Planner:
            <ol className={'mbn'}>
              <li>Import a Diagnostic Report
                <ul className={'mvn'}>
                  <li>In a web browser, navigate to the fully qualified domain name (FQDN) of Operations Manager.</li>
                  <li>Log in to Operations Manager.</li>
                  <li>In the top right corner of the Operations Manager UI, select your username -&gt; <span
                    className={'em-high'}>Settings</span> (in the upper
                    right corner).
                  </li>
                  <li>In the <span className={'em-high'}>Settings</span> screen, click <span
                    className={'em-high'}>Advanced Options</span>.
                  </li>
                  <li>Click <span className={'em-high'}>View Diagnostic Report</span>.</li>
                  <li>Copy the entire diagnostic report and paste it into the diagnostic report textbox in
                    Upgrade Planner.
                  </li>
                  <li>In Upgrade Planner, click <span
                    className={'em-high'}>Add Products From Report</span>.
                  </li>
                </ul>
              </li>
              <li>Add products manually
                <ul className={'mvn'}>
                  <li>Navigate to the <span className={'em-high'}>Search and Add Products</span> textbox.</li>
                  <li>Type in the name of all of the products that you have installed on your foundation.</li>
                </ul>
              </li>
            </ol>
            After you have added all of your products, review the currently selected Target Versions. By default,
            Upgrade Planner chooses the latest release of every product. Choosing the latest version ensures
            that you are upgrading to a version that has the latest security fixes, bug fixes, and features. When all of
            your products have the desired Target Version, select <span
              className={'em-high'}>Generate Plan</span>.
          </div>
          <h1 className="docs-h1">The Upgrade Plan</h1>
          <div className="docs-paragraph">
            After you select <span className={'em-high'}>Generate Plan</span>, you’ll see a series of upgrade
            steps on the right hand side of the
            application, broken into phases. These phases indicate which product upgrades can be executed together in a
            single Apply Change within Operations Manager.
          </div>
          <div className="docs-paragraph">
            Let’s review the important parts of the upgrade plan:
            <ol className={'mbn'}>
              <li>Plan-level Warnings: Plan-level warnings appear at the top of the plan. These may include products
                that have been omitted from the plan due to not being recognized (such as custom tiles) or products
                whose current versions are not supported by the foundation’s current version of Operations Manager. Be sure to
                follow the information in the plan-level warnings to ensure a successful upgrade.
              </li>

              <li>Phase: A phase is a series of upgrade steps that can be done together in one Apply Change in Operations
                Manager.
              </li>

              <li>Step: A step is one upgrade action within a phase. Steps can take a few forms:
                <ol type="i">

                  <li>Import: An Import step requests that you import and stage a product release into Operations Manager.</li>

                  <li>Upgrade: An Upgrade step is a variant of an Import step that applies only to upgrading Operations Manager
                    itself, since Operations Manager does not require importing and staging.
                  </li>

                  <li>Read: Read steps appear at the beginning of phases that include upgrading Operations Manager and Tanzu
                    Application Service (TAS). They contain links to Upgrade Preparation Checklist and Breaking Changes
                    documentation. It is highly recommended to read these documents before upgrading Operations Manager and
                    PAS.
                  </li>

                  <li>Apply Changes: An Apply Changes step requests that you select Apply Changes in the Operations Manager web
                    console. More information on what happens during an Apply Change can be found <a
                    href='https://community.pivotal.io/s/article/What-Happens-After-you-Click-Apply-Changes-on-the-Operations-Manager-Web-Console'>here</a>.
                  </li>
                </ol>
              </li>

              <li>Product Release Info: Product releases in the upgrade plan contain a link to download the release from
                Tanzu Network, a link to the release’s corresponding stemcell, a link to the product’s release notes,
                and its End of General Support date. If a product’s End of General Support date is within the next 3
                months or has already passed, it is highlighted in yellow.
              </li>

              <li>Product Release-level Warnings: Upgrade steps that include product releases that have notable
                challenges, such as extended downtime or configuration changes, are highlighted in the plan. Click the
                warning title to learn how to mitigate its impact.
              </li>

              <li>Copy Plan: The Copy Plan button copies the Upgrade Plan to your device’s clipboard. It can then be
                pasted into an email, Google or Microsoft Word document, or other application.
              </li>
            </ol>
          </div>

          <h1 className="docs-h1">How Upgrade Plans Are Generated</h1>
          <div className="docs-paragraph">
            Upgrade Planner creates plans where service tiles are upgraded to the latest versions that are
            compatible with Operations Manager/Tanzu Application Service first, followed by upgrades to the next minor
            version of Operations Manager/Tanzu Application Service. There are some scenarios where service tiles are
            upgraded in the same phase as Operations Manager/Tanzu Application Service. This usually happens when the service
            tile depends on only one version of Operations Manager/Tanzu Application Service, so we force all three tiles to
            upgrade together.
          </div>

          <h1 className="docs-h1">Upgrade Process</h1>
          <div className="docs-paragraph">
            Upgrade Planner helps with planning an upgrade but once the plan is created, VMware offers
            additional resources to help with executing an upgrade. Those resources include:
            <ol className={'mbn'}>
              <li><a href={'https://docs.pivotal.io/pivotalcf/upgrading/checklist.html'}>Upgrade Preparation
                Checklists</a>: Upgrade Preparation Checklists provide detailed steps to follow when upgrading minor
                versions of VMware Tanzu Platform. Upgrade Preparation Checklists for your specific upgrade path are provided
                in Upgrade Planner.
              </li>
              <li><a href={'https://docs.pivotal.io/pivotalcf/customizing/backup-restore/restore-pcf-bbr.html'}>BOSH
                Backup and Restore</a>: BOSH Backup and Restore (BBR) is a command-line tool for backing up and
                restoring BOSH deployments. VMware recommends that you back up your VMware Tanzu Platform deployment before
                upgrading, to restore in the case of failure.
              </li>
              <li><a href={'https://docs.pivotal.io/platform-automation/v4.0/'}>VMware Tanzu Platform Automation</a>: VMware Tanzu
                Platform Automation provides building blocks to create repeatable and reusable automated pipeline(s) for
                upgrading and installing VMware Tanzu Platform foundations.
              </li>
            </ol>
          </div>

          <h1 className="docs-h1">Features</h1>
          <div className="docs-paragraph">
            <ul className={'mvn'}>
              <li>Generates upgrade plans that include compatible upgrade paths, Release Note links, End of General
                Support (EOGS) dates, Links to Upgrade Checklists, VMware Tanzu Platform Breaking Changes, Notable Upgrade
                Warnings
              </li>
              <li>Ability to manually select product tiles to populate foundation products and versions</li>
              <li>Ability to import diagnostic report to automatically populate foundation products and versions</li>
            </ul>
          </div>

          <h1 className="docs-h1">Terms to Know</h1>
          <div className="docs-paragraph">
            <ul className={'mvn'}>
              <li><span className={'em-high'}>EOGS</span> - A product has reached its End of General Support Life when
                it starts its Technical Guidance phase of
                support (when available). VMware will provide Support Services as per the VMware Support Service Terms
                and
                Conditions for the period of time set forth in the Product Line Support Durations Table. Please see the
              <a href='https://pivotal.io/support/lifecycle_policy'> VMware Support Lifecycle Policy</a> for more
                information.
              </li>
              <li>
                <span className={'em-high'}>Compatible Start State</span> - When the tile versions running on the
                foundation match with the versions certified
                to run on the current version of Operations Manager, per Tanzu Network.
              </li>
            </ul>
          </div>

          <h1 className="docs-h1">Support</h1>
          <div className="docs-paragraph">
            Support services are available to users of Upgrade Planner.
            <ul className={'mvn'}>
              <li>
                Online access to documentation, technical resources, Knowledge Base, and discussion forums.
              </li>
              <li>
                Unlimited number of e-mail based support tickets via <a
                  href='mailto:support@run.pivotal.io'>support@run.pivotal.io</a>
              </li>
              <li>
                Support requests have a target response time of one business day for all severities.
              </li>
            </ul>
          </div>

          <h1 className="docs-h1">Contact Us</h1>
          <div className="docs-paragraph">
            We would love to hear about your experience with Upgrade Planner. The Upgrade Planner team
            can be reached directly at <a
              href='mailto:tas-operability.pdl@broadcom.com'>tas-operability.pdl@broadcom.com</a> or
            on VMware Slack in the <a
              href='https://app.slack.com/client/E8YCM8N9L/C05J47WUAJG'>#tas-operability</a> channel.
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

export { Docs }
