import React, { ChangeEvent, ReactNode, RefObject } from 'react'
// @ts-ignore
import classnames from 'classnames'
import { Icon } from 'pivotal-ui/react/iconography'
import { FlexCol, Grid } from 'pivotal-ui/react/flex-grids'
import { Scrim } from 'pivotal-ui/react/dropdowns/scrim'
import { default as mixin } from 'pivotal-ui/react/mixins'
import Transition from 'pivotal-ui/react/mixins/mixins/transition_mixin'

// @ts-ignore
const defaultToggleNode = ({showIcon, icon, title, ...props}) => (
  <button {...{
    type: 'button',
    'aria-haspopup': 'true',
    ...props
  }}>
    {title}
    {showIcon && <Icon src={icon} className="icon-toggle"/>}
  </button>
)

type DropdownProps = {
  title: string
  id: string
  onEntered: () => void
  scroll: boolean
  itemClassName: string
  className: string[]
  buttonId?: string
  onClick?: (_: ChangeEvent<HTMLInputElement>) => void
  closeOnMenuClick?: () => void
  buttonAriaLabel?: string
  blockingScrim?: string
  border?: string
  buttonClassName?: string
  children?: ReactNode[]
  disableScrim?: string
  showIcon?: boolean
  flat?: string
  link?: string
  menuAlign?: string
  size?: string
  icon?: string
  split?: boolean
  toggle?: string
  floatMenu?: string
  dropdownMenuClassName?: string
  open?: boolean
}

type DropdownState = {
  open: boolean
  buttonId: string
}

export class Dropdown extends mixin(React.Component).with(Transition) {
  containerRef: RefObject<HTMLDivElement>
  state: DropdownState

  static defaultProps = {
    blockingScrim: false,
    closeOnMenuClick: true,
    disableScrim: false,
    icon: 'chevron_down',
    menuAlign: 'none',
    scroll: false,
    showIcon: true,
    size: 'normal'
  }

  constructor(props: DropdownProps) {
    super(props)
    this.state = {
      open: false,
      buttonId: props.buttonId || `dropdown-button-${Math.round(Math.random() * Number.MAX_SAFE_INTEGER)}`
    }

    this.click = this.click.bind(this)
    this.containerRef = React.createRef<HTMLDivElement>()
  }

  componentDidMount() {
    require('pivotal-ui/css/dropdowns')
  }

  click = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({open: !this.state.open})
    this.props.onClick && this.props.onClick(event)
  }

  scrimClick = () => this.setState({open: false})

  menuClick = () => {
    if (!this.props.closeOnMenuClick) return
    this.setState({open: false})
  }

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      closeOnMenuClick, onClick, onEntered, buttonId = this.state.buttonId,
      blockingScrim, border, buttonAriaLabel, buttonClassName, children, className, disableScrim, showIcon,
      flat, link, menuAlign, size, icon, split, title, toggle, floatMenu, scroll, itemClassName, dropdownMenuClassName, ...props
    } = this.props

    const open = this.props.hasOwnProperty('open') ? this.props.open : this.state.open
    const buttonStyleClasses = classnames('dropdown-button dropdown-toggle', buttonClassName)
    const noTitle = typeof title === 'undefined' || title === null || title.length === 0
    const menuId = `${buttonId}-menu`

    const toggleProps = {
      showIcon: noTitle || split || showIcon,
      icon,
      onClick: this.click,
      title: !split && title,
      className: buttonStyleClasses,
      'aria-label': buttonAriaLabel,
      'aria-expanded': open,
      'aria-controls': menuId,
      id: buttonId
    }

    const toggleNode = defaultToggleNode(toggleProps)
    const menuVisibility = open ? 'dropdown-open' : 'dropdown-closed'

    const dropdownClasses = classnames('dropdown', {
      'dropdown-flat': flat,
      'dropdown-split': split,
      'dropdown-link': link,
      'dropdown-lg': size === 'large',
      'dropdown-sm': size === 'small',
      'dropdown-icon-only': !split && noTitle
    }, menuVisibility, className)

    const dropdownMenuClasses = classnames('dropdown-menu',
      {
        'dropdown-border': border,
        'dropdown-menu-right': menuAlign === 'right',
        'dropdown-menu-left': menuAlign === 'left',
        'dropdown-menu-float': split || flat || link || floatMenu || noTitle || menuAlign !== 'none',
        'dropdown-menu-scroll': scroll
      },
      dropdownMenuClassName
    )
    const dropdownOptions = (
      <div className={dropdownMenuClasses}>
        <ul aria-labelledby={buttonId} role="menu" onClick={this.menuClick} id={menuId}>
          {React.Children.map(children, (child) => {
            let childElement = child as React.ReactElement
            if (childElement) {
              if (childElement.props.header) {
                return (
                  <li className={itemClassName + ' dropdown-heading'} role="heading">
                    {React.cloneElement(childElement, {role: 'heading', ...childElement.props})}
                  </li>
                )
              } else if (childElement.props.divider) {
                return (
                  <li className={itemClassName} role="none">
                    {React.cloneElement(childElement, {role: 'menuitem', ...childElement.props})}
                  </li>
                )
              } else {
                return (
                  <li className={itemClassName + ` ${childElement.props.className}`} role="menuitem">
                    {React.cloneElement(childElement, {role: 'menuitem', ...childElement.props})}
                  </li>
                )
              }
            }
            return null
          })}
        </ul>
      </div>
    )

    return (
      <Scrim containerRef={this.containerRef} onScrimClick={this.scrimClick}>
        <div className={dropdownClasses} {...props} ref={this.containerRef}>
          {split ? <Grid gutter={false}>
            <FlexCol className="dropdown-label">{title}</FlexCol>
            <FlexCol fixed className="dropdown-icon-col col-middle">
              {toggleNode}
            </FlexCol>
          </Grid>
            : toggleNode}
          {(blockingScrim && open && !disableScrim) && <div className="scrim" onClick={this.scrimClick}/>}
          {dropdownOptions}
        </div>
      </Scrim>
    )
  }
}
