import store from '../stores/store'
import { RECALCULATE_COMPATIBILITY_ACTION } from './actions'
import { Product } from '../repositories/products'
import { OPSMAN_SLUG, TAS_SLUG, TKGI_SLUG } from '../constants/constants'

export type RecalculateCompatibilityAction = {
  type: typeof RECALCULATE_COMPATIBILITY_ACTION
  platformProductNames: Product[]
  updateTargetVersion: boolean
  minimalServiceTileTargetVersion: boolean
}

export function recalculateCompatibility(updateTargetVersion: boolean): RecalculateCompatibilityAction {
  let platformProductNames = store.getState().products.filter((product: Product) => [OPSMAN_SLUG, TAS_SLUG, TKGI_SLUG].includes(product.slug))

  return {
    type: RECALCULATE_COMPATIBILITY_ACTION,
    platformProductNames: platformProductNames,
    updateTargetVersion: updateTargetVersion,
    minimalServiceTileTargetVersion: store.getState().selectMinimalServiceTileTargetVersions
  }
}
