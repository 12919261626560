import { Divider } from 'pivotal-ui/react/dividers'
import PlanStepsError from '../plan_steps_error'
import React from 'react'
import { ErrorInfoState } from '../../error_info/error_info_types';

type FailurePanelProps = {
    errorInfo: ErrorInfoState
}

export const FailurePanel = ({errorInfo}: FailurePanelProps) => (
  <div className={'pvxxl'}>
    <h2><a href={'#plan'}>Your Upgrade Plan</a></h2>
    <Divider/>
    <div className={'mbxxl'}>
      <PlanStepsError error={errorInfo}/>
    </div>
  </div>
)
