import { Icon } from 'pivotal-ui/react/iconography'
import React from 'react'
import { ReleaseAlert } from '../../repositories/upgrade_plan'

type StepAlertProps = {
  alerts: ReleaseAlert[]
}

export const StepAlert = ({alerts}: StepAlertProps) => (
  <div className='alert'>
    <div className='prm'>
      <Icon src={'warning'} verticalAlign='baseline'/>
    </div>
    <div className='type-sm'>
      <p className='man em-high pbs'>Warning(s):</p>
      <ul>
        {alerts.map((alert: ReleaseAlert, index: number) => (<li className='man pbs' key={index}>
          <a href={alert.link} target="_blank" rel="noopener noreferrer">{alert.message}</a>
        </li>))}
      </ul>
    </div>
  </div>
)
