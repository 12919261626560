import React from 'react'
import { connect } from 'react-redux'
import { Panel } from 'pivotal-ui/react/panels'
import ProductConfigurationPasteMenu from './product_configuration_paste_menu'
import AnalyticsWrapper from '../services/analytics_wrapper'
import { ChosenProduct } from '../chosen_products/chosen_products_types'
import { FoundationRepository } from '../repositories/foundation'

type UpgradePlannerProps = {
  products?: ChosenProduct[]
  foundationRepository: FoundationRepository,
  analyticsWrapper: AnalyticsWrapper
}

const UpgradePlanner = ({products, foundationRepository, analyticsWrapper}: UpgradePlannerProps) => {
  const loadingState = (<h1>Loading...</h1>)
  if (products === undefined || products.length === 0) return loadingState

  return (
    <div>
      <h2 className='inline pbl'>Plan An Upgrade</h2>
      <p className='pbl'>
        This upgrade planner takes your current VMware Tanzu Platform version and creates a sequenced upgrade path to your target version.
        Start by entering all products installed on your VMware Tanzu Platform Foundation with your diagnostic report.
      </p>

      <h2 className='inline pbl'>Import Foundation with Diagnostic Report </h2>
      <p className={'pbl'}>
        Copy your <a href="https://community.pivotal.io/s/article/how-to-get-the-diagnostic-report-from-an-operations-manager" target="_blank" rel="noopener noreferrer"> Diagnostic Report</a> from Operations Manager,
        paste it into the field below and click Add Products From Report.
        This populates your products.
        If you do not have a diagnostic report, you may add products manually in the Manually Add Tiles section below.
      </p>

      <Panel className={'bg-light-gray box-shadow-1 border paxxl'} bodyClassName={'opt-inner-class'}>
        <ProductConfigurationPasteMenu
          foundationRepository={foundationRepository}
          analyticsWrapper={analyticsWrapper}
        />
      </Panel>
    </div>
  )
}

export default connect(({products}: {products: ChosenProduct[]}) => {
  return {
    products
  }
})(UpgradePlanner)

export {UpgradePlanner}
