import {
  DiagnosticReportRecommendationAction,
  HIDE_RECOMMENDATION_BUBBLE,
  MinorRecommendation,
  NO_RECOMMENDED_VERSION,
  RECOMMENDED_VERSION
} from '../actions/diagnostic_report_recommendation'

export default function diagnosticReportRecommendation(diagnosticReportRecommendation: MinorRecommendation = MinorRecommendation.NO_RECOMMENDATION, action: DiagnosticReportRecommendationAction): MinorRecommendation {
  switch (action.type) {
  case RECOMMENDED_VERSION:
    return MinorRecommendation.RECOMMENDATION_SHOW_BUBBLE
  case NO_RECOMMENDED_VERSION:
    return MinorRecommendation.NO_RECOMMENDATION
  case HIDE_RECOMMENDATION_BUBBLE:
    return MinorRecommendation.RECOMMENDATION_HIDE_BUBLE
  default:
    return diagnosticReportRecommendation
  }
}
