export const FAILED_TO_FETCH_PRODUCT_DETAILS_ACTION = 'FAILED_TO_FETCH_PRODUCT_DETAILS'

export type FailedToFetchProductDetailsAction = {
  type: typeof FAILED_TO_FETCH_PRODUCT_DETAILS_ACTION,
  slug: string
}

export const failedToFetchProductDetails = (slug: string): FailedToFetchProductDetailsAction => {
  return {
    type: FAILED_TO_FETCH_PRODUCT_DETAILS_ACTION,
    slug
  }
}
