import React from 'react'
import { UpgradePortalStore } from '../stores/store'
import { ChosenProduct } from '../chosen_products/chosen_products_types'
import { connect } from 'react-redux'
import { updateUrlParamsAndCopyToClipboard } from '../services/url_params'
import AnalyticsWrapper from '../services/analytics_wrapper'

type ButtonCopyPlanUrlProps = {
  chosenProducts: ChosenProduct[]
  analyticsWrapper: AnalyticsWrapper
}

export const ButtonCopyPlanUrl = ({chosenProducts, analyticsWrapper}: ButtonCopyPlanUrlProps) => {
  return (
    <div id='copy-plan-url-btn' onClick={() => {
      analyticsWrapper.fireEvent('Button', 'Click', 'Copy Plan URL Button')
      updateUrlParamsAndCopyToClipboard({chosenProducts})
    }}>URL</div>
  )
}

const mapStateToProps = ({chosenProducts}: UpgradePortalStore) => {
  return {chosenProducts}
}

export default connect(mapStateToProps)(ButtonCopyPlanUrl)
