import { LOADING_STATES } from '../reducers/upgrade_plan_loading_state'
import { changeUpgradePlanLoadingState } from './change_upgrade_plan_loading_state'
import loadErrorInfo from '../error_info/load_error_info_action'
import clearProductErrors from '../error_info/clear_error_info_action'
import signalServerDown from '../server_status/signal_server_down_action'
import { PlanErrorTypes } from '../error_info/error_info_types'
import UpgradePlanConfiguration from '../services/upgrade_plan_config'
import { Plan, PlanRepository } from '../repositories/upgrade_plan'
import { Action, Dispatch } from 'redux'

export const LOAD_UPGRADE_PLAN = 'LOAD_UPGRADE_PLAN'


export interface LoadUpgradePlanAction extends Action {
  type: typeof LOAD_UPGRADE_PLAN,
  value: Plan
}

const loadUpgradePlan = (upgradePlan: Plan): LoadUpgradePlanAction => {
  return {
    type: LOAD_UPGRADE_PLAN,
    value: upgradePlan
  }
}

export const loadUpgradePlanAsync = (upgradePlanRepository: PlanRepository, upgradeRequestConfiguration: UpgradePlanConfiguration) => {

  const loadUpgradePlanThunk = (dispatch: Dispatch) => {
    dispatch(clearProductErrors())
    dispatch(changeUpgradePlanLoadingState(LOADING_STATES.PENDING))

    return upgradePlanRepository.getPlan(dispatch, upgradeRequestConfiguration.apiRequestParams())
      .then((plan: Plan) => {
        dispatch(loadUpgradePlan(plan))
        dispatch(changeUpgradePlanLoadingState(LOADING_STATES.SUCCESS))
      })
      .catch((error) => {
        if (error.json) {
          error.json().then((body: any) => {
            dispatch(loadErrorInfo(body.error))
            dispatch(changeUpgradePlanLoadingState(LOADING_STATES.FAILURE))
          })
        } else if (error.message === 'Failed to fetch') {
          dispatch(loadErrorInfo({type: PlanErrorTypes.SERVER_DOWN}))
          dispatch(changeUpgradePlanLoadingState(LOADING_STATES.FAILURE))
          dispatch(signalServerDown('Server is down at the moment.'))
        } else {
          dispatch(loadErrorInfo({type: PlanErrorTypes.UNKNOWN_ERROR}))
          dispatch(changeUpgradePlanLoadingState(LOADING_STATES.FAILURE))
        }
      })
  }

  return loadUpgradePlanThunk
}
