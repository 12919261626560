import React from 'react'
import { FlexCol, Grid } from 'pivotal-ui/react/flex-grids'
import { ErrorAlert } from 'pivotal-ui/react/alerts'
import UpgradePlanner from './upgrade_planner'
import ChosenProductsList from '../chosen_products/chosen_products_list'
import ProductSelectionMenu from './product_selection_menu'
import ButtonGeneratePlan from './button_generate_plan'
import UpgradeStepsPanel from './upgrade_steps_panel/upgrade_steps_panel'
import { ServerStatusResponse, StatusType } from '../server_status/server_status_types'
import AnalyticsWrapper from '../services/analytics_wrapper'
import { Product, ProductRepository } from '../repositories/products'
import { PlanRepository } from '../repositories/upgrade_plan'
import OpsManagerMinorTargetVersionSelector from './ops_manager_minor_target_version_selector'
import { FoundationRepository } from '../repositories/foundation'

type AppBodyProps = {
  serverStatus: ServerStatusResponse
  analyticsWrapper: AnalyticsWrapper
  productRepository: ProductRepository
  foundationRepository: FoundationRepository
  upgradePlanRepository: PlanRepository
  products: Product[]
}

export class AppBody extends React.Component<AppBodyProps> {
  render() {
    if (this.props.serverStatus.type !== StatusType.HEALTHY) {
      this.props.analyticsWrapper.fireEvent('Error', 'Load', this.props.serverStatus.type)

      return (
        <Grid className={'app-body'}>
          <div className={'col col-12 mtxxxl'}>
            <ErrorAlert withIcon>
              {this.props.serverStatus.message} Please try again and if this error still appears,
              contact <a href={`mailto:${'tas-operability.pdl@broadcom.com'}`}>tas-operability.pdl@broadcom.com</a>.
            </ErrorAlert>
          </div>
        </Grid>
      )
    } else {
      return (
        <Grid className={'app-body'}>
          <FlexCol className={'col-24 mtxxl mlxxl col-fixed'}>
            <UpgradePlanner
              analyticsWrapper={this.props.analyticsWrapper}
              foundationRepository={this.props.foundationRepository}
            />
            <div id={'ops-manager-target-selector'}>
              <OpsManagerMinorTargetVersionSelector
                analyticsWrapper={this.props.analyticsWrapper}
              />
            </div>
            <ChosenProductsList/>
            <ProductSelectionMenu
              analyticsWrapper={this.props.analyticsWrapper}
              productRepository={this.props.productRepository}
            />
            <ButtonGeneratePlan
              upgradePlanRepository={this.props.upgradePlanRepository}
              analyticsWrapper={this.props.analyticsWrapper}
            />
            <div className={'plan'}>
              <UpgradeStepsPanel
                analyticsWrapper={this.props.analyticsWrapper}
                upgradePlanRepository={this.props.upgradePlanRepository}
              />
            </div>
          </FlexCol>
        </Grid>
      )
    }
  }
}

export default AppBody
