import React from 'react'

import { WarningAlert } from 'pivotal-ui/react/alerts'
import { ChosenProduct } from './chosen_products_types'
import { dismissDuplicatedProductWarning, DismissDuplicatedProductWarningAction } from '../actions/dismiss_duplicated_product_warning'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

type DuplicateChosenProductsWarningProps = {
  chosenProducts: ChosenProduct[]
  dismissDuplicatedProductWarning: () => DismissDuplicatedProductWarningAction
}

const DuplicateChosenProductsWarning = (props: DuplicateChosenProductsWarningProps) => {
  let duplicateProducts = props.chosenProducts.filter(product => product.isDuplicate).map(product => {
    return <li key={product.slug}>{((product.name) ? product.name : product.slug)}</li>
  })

  let duplicateProductWarningContainer
  if (duplicateProducts.length > 0) {
    duplicateProductWarningContainer = (
      <WarningAlert dismissable onDismiss={props.dismissDuplicatedProductWarning}>
        The following products are already in the product list:
        <ul className={'mtn mbn'}>
          {duplicateProducts}
        </ul>
      </WarningAlert>
    )
  }

  return (
    <div id='duplicate-warning-container'>
      {duplicateProductWarningContainer}
    </div>
  )
}

export { DuplicateChosenProductsWarning }

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismissDuplicatedProductWarning: () => dispatch(dismissDuplicatedProductWarning())
})

export default connect(null, mapDispatchToProps)(DuplicateChosenProductsWarning)
