import signalPivnetDown from '../server_status/signal_pivnet_down_action'
import signalServerDown from '../server_status/signal_server_down_action'
import { UPServerFetcher } from '../services/up_server_fetcher'
import { Dispatch } from 'redux'

export interface Product {
  slug: string
  name: string
}

export interface ProductRelease {
  version: string
  id: number
  availability: string
  endOfSupportDate: string | null
  isCompatible: boolean
  dependencies: { [id: string] : ProductDependency[] }
}

export interface ProductDependency {
  version: string
  versionType: string
}

export interface ProductSlugVersion {
  slug: string
  current: string
  target: string | null
}

export interface ProductDetails extends ProductSlugVersion {
  name: string
  pivotalPlatform: boolean

  lifecycleState: string
  logoUrl: string | null
  category: string | null
  releases: ProductRelease[]
}

export interface ProductRepository {
  getProducts(dispatch: Dispatch): Promise<Product | Product[]>

  getProductDetails(dispatch: Dispatch, slug: string): Promise<ProductDetails>
}

export default class ProductRepositoryImpl implements ProductRepository {
  readonly upServerFetcher: UPServerFetcher

  constructor(upServerFetcher: UPServerFetcher) {
    this.upServerFetcher = upServerFetcher
  }

  getProducts(dispatch: Dispatch): Promise<Product | Product[]> {
    const request = this.makeProductRequest()

    return this.upServerFetcher.fetch(request)
      .then(response => {
        if (response.status === 500) {
          response.json().then((responseJSON) => {
            dispatch(signalPivnetDown(responseJSON.message))
          })
          return Promise.resolve([])
        }
        return response.json()
      })
      .catch((err) => {
        dispatch(signalServerDown('The Upgrade Planner server is down.'))

        return Promise.reject(err)
      })
  }

  getProductDetails(dispatch: Dispatch, slug: string): Promise<ProductDetails> {
    return this.upServerFetcher.fetch(this.makeProductDetailsRequest(slug))
      .then((response) => {
        if (response.status === 404) return Promise.reject()
        return response.json()
      }).then((product) => {
        product.releases.forEach((release: ProductRelease) => {
          release.isCompatible = true
        })
        return product
      })
  }

  private makeProductDetailsRequest(slug: string): Request {
    const myHeaders = new Headers()

    myHeaders.append('Accept', 'application/json')

    const options: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default',
      redirect: 'follow'
    }

    const url = this.upServerFetcher.generateUrl(`/v1/products/${slug}`)

    return new Request(
      url,
      options
    )
  }

  private makeProductRequest(slug: string = ''): Request {
    const myHeaders = new Headers()

    myHeaders.append('Accept', 'application/json')

    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
      mode: 'cors',
      cache: 'default'
    }

    const url = this.upServerFetcher.generateUrl(`/v1/products${slug}`)

    return new Request(
      url,
      options
    )
  }
}
