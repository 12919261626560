import { DowngradedProduct, PathNotFoundProduct } from '../components/plan_steps_error'

export type ErrorInfoState =
  NoErrorInfoState |
  UnknownErrorInfoState |
  InconsistentStateErrorInfoState |
  DowngradeErrorInfoState |
  PathNotFoundErrorInfoState |
  UnsatisfiedDependentsErrorInfoState |
  TimeoutErrorInfoState |
  NoValidStatesState |
  ServerDownState

export enum PlanErrorTypes {
  NO_ERROR = 'NO_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  INCONSISTENT_STATE_CURRENT = 'INCONSISTENT_STATE_CURRENT',
  INCONSISTENT_STATE_TARGET = 'INCONSISTENT_STATE_TARGET',
  DOWNGRADE = 'DOWNGRADE',
  PATH_NOT_FOUND = 'PATH_NOT_FOUND',
  DEPENDENTS_NOT_SATISFIED = 'DEPENDENTS_NOT_SATISFIED',
  TIMEOUT = 'TIMEOUT',
  NO_VALID_STATES = 'NO_VALID_STATES',
  SERVER_DOWN = 'SERVER_DOWN'
}

export interface NoErrorInfoState {
  type: typeof PlanErrorTypes.NO_ERROR
}

export interface UnknownErrorInfoState {
  type: typeof PlanErrorTypes.UNKNOWN_ERROR
}

export interface InconsistentStateErrorInfoState {
  type: typeof PlanErrorTypes.INCONSISTENT_STATE_CURRENT | typeof PlanErrorTypes.INCONSISTENT_STATE_TARGET
  affected: AffectedRelease[]
}

export interface DowngradeErrorInfoState {
  type: typeof PlanErrorTypes.DOWNGRADE
  affected: DowngradedProduct[]
}

export interface PathNotFoundErrorInfoState {
  type: typeof PlanErrorTypes.PATH_NOT_FOUND
  affected: PathNotFoundProduct[]
}

export interface UnsatisfiedDependentsErrorInfoState {
  type: typeof PlanErrorTypes.DEPENDENTS_NOT_SATISFIED
  affected: DependentsNotSatisfiedProduct
}

export interface TimeoutErrorInfoState {
  type: typeof PlanErrorTypes.TIMEOUT
}

export interface NoValidStatesState {
  type: typeof PlanErrorTypes.NO_VALID_STATES
}

export interface ServerDownState {
  type: typeof PlanErrorTypes.SERVER_DOWN
}

export interface AffectedRelease {
  name: string
  link: string
  slug: string
  version?: string
  currentVersion?: string
  targetVersion?: string
  dependencies?: AffectedProduct[]
}

export type DependentsNotSatisfiedProduct = {
  name: string
  slug: string
  currentVersion: string
  targetVersion: string
}

export interface AffectedProduct {
  name: string
  link: string
  slug: string
  versionSpecifiers: string[]
}

export const LOAD_ERROR_INFO = 'LOAD_ERROR_INFO'
export const CLEAR_PRODUCT_ERROR = 'CLEAR_PRODUCT_ERROR'

interface LoadErrorAction {
  type: typeof LOAD_ERROR_INFO
  value: ErrorInfoState
}

interface ClearErrorAction {
  type: typeof CLEAR_PRODUCT_ERROR
}

export type ErrorInfoActionTypes = LoadErrorAction | ClearErrorAction
