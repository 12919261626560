import { SelectMinimalServiceTileTargetVersionsAction } from '../actions/select_minimal_service_tile_target_versions'
import { SELECT_MINIMAL_SERVICE_TILE_TARGET_VERSIONS_ACTION } from '../actions/actions'

export default function selectMinimalServiceTileTargetVersions(selectMinimalServiceTileTargetVersions: boolean = false, action: SelectMinimalServiceTileTargetVersionsAction) {
  switch (action.type) {
  case SELECT_MINIMAL_SERVICE_TILE_TARGET_VERSIONS_ACTION:
    return action.minimalServiceTileTargetsSelected
  default:
    return selectMinimalServiceTileTargetVersions
  }
}
