import React from 'react'
import AnalyticsWrapper from '../services/analytics_wrapper'

type ButtonCopyPlanProps = {
  analyticsWrapper: AnalyticsWrapper
}

const ButtonCopyPlan = ({analyticsWrapper}: ButtonCopyPlanProps) => {
  return (
    <div id='copy-plan-btn' onClick={() => {
      analyticsWrapper.fireEvent('Button', 'Click', 'Copy Plan Button')

      const copy = document.getElementById('copyable-plan')!
      const selection = window.getSelection()!
      const range = document.createRange()

      range.selectNodeContents(copy)
      selection.removeAllRanges()
      selection.addRange(range)

      try {
        document.execCommand('copy')
        selection.removeAllRanges()
      } catch (e) {
        analyticsWrapper.fireEvent('Error', 'Click', 'Copy Plan Button')
      }
    }}>Copy Plan Text</div>
  )
}

export default ButtonCopyPlan
