import React from 'react'

import { connect } from 'react-redux'
import changeReleaseDropdownVersionFilter from './change_release_dropdown_version_filter'
import { DropdownItem } from './dropdown_item'
import { Input } from 'pivotal-ui/react/inputs'
import { Dispatch } from 'redux'
import { UpgradePortalStore } from '../stores/store'
import { ChosenProduct } from './chosen_products_types'

const handleKeyDown = () => {
}

const handleClose = () => {
}

const onChange = (filterText: string, dispatch: Dispatch) => {
  dispatch(changeReleaseDropdownVersionFilter(filterText))
}

type ReleaseDropdownFilterProps = {
  type: string,
  product: ChosenProduct,
  dispatch: Dispatch,
  filterText?: string
}

const ReleaseDropdownFilter = ({type, product, dispatch, filterText}: ReleaseDropdownFilterProps) => {
  return (
    <DropdownItem>
      <Input type='text'
        aria-labelledby={product.slug + '-row-label ' + type + '-column-label'}
        value={filterText}
        className='form-control border-left-0 border-right-0 border-top-0'
        placeholder='Filter versions...'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const filterText = event.target.value
          onChange(filterText, dispatch)
        }}
        onSelect={handleClose}
        onKeyDown={handleKeyDown}
        onClick={(event: MouseEvent) => {
          event.stopPropagation()
        }}
        name={'release-dropdown-filter'}
      />
    </DropdownItem>
  )
}

const mapStateToProps = (state: UpgradePortalStore) => {
  return state
}

export default connect(mapStateToProps)(ReleaseDropdownFilter)

export { ReleaseDropdownFilter }
