import { Product } from './repositories/products'

export type FilteredProduct = {
  value: string
  product: Product | null
}

export class ProductFilter {
  readonly products: Product[]
  readonly searchText: string

  constructor(products: Product[], searchText: string) {
    this.products = products
    this.searchText = searchText
  }

  filter(): FilteredProduct[] {
    if (this.searchText.length === 0) return this.format(this.products)
    if (this.products.length === 0) return []

    let reg: RegExp

    try {
      reg = new RegExp(this.searchText, 'i')
    } catch {
      reg = new RegExp(this.escapeRegexCharacters(this.searchText), 'i')
    }

    const matches = this.products.filter(product => {
      return this.regexMatchesAnyField(reg, product)
    }).sort((a, b) => {
      var nameA = a.name.toLowerCase()
      var nameB = b.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })

    return this.format(matches)
  }

  private escapeRegexCharacters(s: string): string {
    return s.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, '\\$&')
  }

  private regexMatchesAnyField(regex: RegExp, product: Product): boolean {
    const values = Object.values(product)


    for (let i = 0; i < values.length; i++) {
      if (regex.test(values[i])) return true
    }

    return false
  }

  private format(products: Product[]): FilteredProduct[] {
    return products.map(matchingProduct => {
      return {
        value: matchingProduct.name,
        product: matchingProduct
      }
    })
  }
}

export function productFilterFactory(products: Product[], searchText: string): FilteredProduct[] {
  return new ProductFilter(products, searchText).filter()
}
