import { CHANGE_UPGRADE_PLAN_LOADING_STATE, ChangeUpgradePlanLoadingStateAction } from '../actions/change_upgrade_plan_loading_state'

export enum LOADING_STATES {
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT'
}

export default function upgradePlanLoadingState(state: LOADING_STATES = LOADING_STATES.DEFAULT, action: ChangeUpgradePlanLoadingStateAction): LOADING_STATES {
  switch (action.type) {
  case CHANGE_UPGRADE_PLAN_LOADING_STATE:
    return action.value
  default:
    return state
  }
}
