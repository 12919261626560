import {
  LOAD_ERROR_INFO,
  ErrorInfoState
} from "./error_info_types"

const loadErrorInfo = (value: ErrorInfoState) => {
  return {
    type: LOAD_ERROR_INFO,
    value
  }
}

export default loadErrorInfo
