import {
  ServerStatusActionTypes,
  ServerStatusResponse,
  SIGNAL_PIVNET_DOWN,
  SIGNAL_SERVER_DOWN,
  StatusType
} from './server_status_types'

const initialState: ServerStatusResponse = {
  type: StatusType.HEALTHY,
  message: ''
}

export default function serverStatus(state: ServerStatusResponse = initialState, action: ServerStatusActionTypes): ServerStatusResponse {
  switch (action.type) {
  case SIGNAL_SERVER_DOWN:
    return {type: StatusType.SERVER_DOWN, message: action.message}
  case SIGNAL_PIVNET_DOWN:
    return {type: StatusType.PIVNET_DOWN, message: action.message}
  default:
    return {type: StatusType.HEALTHY, message: ''}
  }
}
