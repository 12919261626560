import { ProductDetails } from '../repositories/products'
import { Dispatch } from 'redux'
import {
  DetailsFoundationProductRequest, DetailsFoundationRequest, Foundation, FoundationRepository
} from '../repositories/foundation'
import { FAILED_TO_FETCH_FOUNDATION_DETAILS_ACTION, SELECTED_NEW_PRODUCT_ACTION } from './actions'
import receivedProductDetails from '../chosen_products/received_product_details_action'
import signalServerDown from '../server_status/signal_server_down_action'
import { updateOpsManagerMinorTargetVersion } from './select_ops_manager_minor_target_version'
import { failedToFetchProductDetails } from '../chosen_products/failed_to_fetch_product_details_action'
import { doneDiagnosticReportLoading, startDiagnosticReportLoading } from './diagnostic_report_loading'
import { importedDiagnosticReport } from './diagnostic_report_imported'
import { didNotRecommendAVersion, recommendedVersion } from './diagnostic_report_recommendation'
import { recalculateCompatibility } from './recalculate_compatibility'
import { selectMinimalServiceTileTargetVersions } from './select_minimal_service_tile_target_versions'
import { recommendOpsmanVersion } from './recommend_opsman_version'

export type FailedToFetchFoundationDetailsAction = {
  type: typeof FAILED_TO_FETCH_FOUNDATION_DETAILS_ACTION,
  slugs: string[]
}

export function failedToFetchFoundationDetails(products: DetailsFoundationProductRequest[]): FailedToFetchFoundationDetailsAction {
  return {
    type: FAILED_TO_FETCH_FOUNDATION_DETAILS_ACTION,
    slugs: products.map((product) => product.slug)
  }
}

type Return = (dispatch: Dispatch) => Promise<void>
export type DiagnosticReportProductsHandler = (foundationRepository: FoundationRepository, products: ProductDetails[]) => Return

export function addProductsFromDiagnosticReport(foundationRepository: FoundationRepository, products: ProductDetails[]): (dispatch: Dispatch) => Promise<void> {
  return (dispatch: Dispatch): Promise<void> => {
    dispatch(startDiagnosticReportLoading())
    dispatch(selectMinimalServiceTileTargetVersions(false))

    let allProducts: DetailsFoundationRequest = []
    products.forEach((product: ProductDetails) => {
      dispatch({
        type: SELECTED_NEW_PRODUCT_ACTION,
        value: {
          slug: product.slug,
          name: product.name,
          current: product.current
        }
      })
      allProducts.push({slug: product.slug, current: product.current})
    })

    return foundationRepository.getFoundationDetails(allProducts)
      .then((foundation: Foundation) => {
        dispatch(updateOpsManagerMinorTargetVersion(foundation.targetOpsManagerMinorVersion))
        dispatch(importedDiagnosticReport())

        allProducts.forEach((product: DetailsFoundationProductRequest) => {
          const productDetail = foundation.products.find((p: ProductDetails) => p.slug === product.slug)
          const recommendation = foundation.recommendedVersions.find((p) => p.slug === product.slug)
          if (productDetail === undefined) {
            dispatch(failedToFetchProductDetails(product.slug))
          } else {
            if (recommendation !== undefined) {
              productDetail.target = recommendation.target.toString()
            }
            dispatch(receivedProductDetails(product.slug, productDetail))
          }
        })

        if(foundation.canRecommend) {
          dispatch(recommendedVersion())
        } else {
          dispatch(didNotRecommendAVersion())
        }

        dispatch(recalculateCompatibility(false))
        dispatch(recommendOpsmanVersion(foundation.targetOpsManagerMinorVersion))
        scrollProductListToTopOfScreen()
      }).catch((error) => {
        if (error !== undefined && error.message === 'Failed to fetch') {
          dispatch(signalServerDown('Server is down at the moment.'))
        } else {
          dispatch(failedToFetchFoundationDetails(allProducts))
        }
      }).finally(() => {dispatch(doneDiagnosticReportLoading())})
  }
}

const scrollProductListToTopOfScreen = () => {
  const element = document.querySelector('#ops-manager-target-selector')
  if (element === null) {
    return
  }

  const topPos = element!.getBoundingClientRect().top + window.pageYOffset

  window.scrollTo({
    top: topPos,
    behavior: 'smooth'
  })
}
