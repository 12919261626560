import React, { ReactElement } from 'react'

import { connect } from 'react-redux'
import { chooseProductVersion, ChooseProductVersionAction, VersionType } from './choose_product_version_action'
import changeReleaseDropdownVersionFilter, { ChangeReleaseDropdownVersionAction } from './change_release_dropdown_version_filter'

import { Dropdown } from '../components/dropdown'
import { DropdownItem } from './dropdown_item'

import ReleaseDropdownFilter from './release_dropdown_filter'
import NotApplicable from '../components/not_applicable'
import { ChosenProduct, PlanAlertLevels } from './chosen_products_types'
import * as Constants from '../constants/constants'
import { METRICS_FORWARDER_SLUG } from '../constants/constants'
import { ProductRelease } from '../repositories/products'
import { Dispatch } from 'redux'
import {
  selectMinimalServiceTileTargetVersions,
  SelectMinimalServiceTileTargetVersionsAction
} from '../actions/select_minimal_service_tile_target_versions'
import { recalculateCompatibility } from '../actions/recalculate_compatibility'

type IProps = {
  type: VersionType
  product: ChosenProduct
  releases: ProductRelease[]
  version: string | null
  releaseDropdownVersionFilter: string
  error: PlanAlertLevels
  classNames: string[]
  onChooseProductVersion: (type: VersionType, product: string, version: string | null) => ChooseProductVersionAction
  onDropdownEnter: () => ChangeReleaseDropdownVersionAction
  disableMinimalServiceTileTargetVersion: () => SelectMinimalServiceTileTargetVersionsAction
  onVersionChange?: () => void
  recalculateCompatibility?: () => void
}

export const CurrentTargetDropdown = (props: IProps) => {
  const {type, product} = props
  let Component = ReleaseDropdown
  if (type === 'target' && product.pivotalPlatform) {
    Component = PlatformProductTargetDropdown
  } else if (type === 'target' && !product.pivotalPlatform) {
    Component = NonPlatformProductTargetDropdown
  }

  return (<Component onVersionChange={undefined} {...props}/>)
}

export const PlatformProductTargetDropdown = (props: IProps) => {
  const {releases} = props
  const allUserReleases = releases.filter((r) => r.availability === 'All Users' && r.isCompatible)

  return (<ReleaseDropdown {...{...props, releases: allUserReleases}} onVersionChange={() => {
    if (props.recalculateCompatibility !== undefined) {
      props.recalculateCompatibility()
    }
  }}/>
  )
}

export const ReleaseDropdown = (
  {
    type,
    product,
    releases,
    version,
    releaseDropdownVersionFilter,
    error,
    classNames,
    onChooseProductVersion,
    onDropdownEnter,
    onVersionChange
  }: IProps) => {
  let filterRegex = new RegExp(releaseDropdownVersionFilter, 'i')

  const filterBox = [(
    <ReleaseDropdownFilter type={type} product={product} key='filter'/>
  )]

  const releaseLinks = filterBox.concat(releases.filter(r => {
    return filterRegex.test(r.version)
  }).map(r => {
    const onSelect = () => {
      onChooseProductVersion(type, product.slug, r.version)
      if (onVersionChange !== undefined) {
        onVersionChange()
      }
    }

    return (
      <DropdownItem onSelect={onSelect} key={`${type}-${product.slug}-${r.version}=${r.id}`}>
        {r.version}
      </DropdownItem>)
  }))

  if(classNames === undefined) {
    classNames = []
  }

  if (error === PlanAlertLevels.ERROR) {
    classNames = classNames.concat(['release-dropdown', 'aligner-item', 'error'])
  } else if (error === PlanAlertLevels.WARNING) {
    classNames = classNames.concat(['release-dropdown', 'aligner-item', 'warning'])
  } else if (error === PlanAlertLevels.INFO) {
    classNames = classNames.concat(['release-dropdown', 'aligner-item', 'info'])
  } else {
    classNames = classNames.concat(['release-dropdown', 'aligner-item'])
  }

  if (shouldDisableDropdown(type, product, releases)) {
    return <NotApplicable slug={product.slug} type={type}/>
  }

  let titleValue = version ? version : 'Select Version'

  return (
    // @ts-ignore
    <Dropdown
      buttonClassName={classNames}
      title={titleValue}
      id={`${product.slug}-release-${type}-select`}
      onEntered={() => {
        onDropdownEnter()
      }}
      scroll={true}
      itemClassName={'pan'}
    >
      {releaseLinks}
    </Dropdown>
  )
}

export const NonPlatformProductTargetDropdown = (
  {
    type,
    product,
    releases,
    version,
    releaseDropdownVersionFilter,
    error,
    classNames,
    onChooseProductVersion,
    onDropdownEnter,
    disableMinimalServiceTileTargetVersion
  }: IProps) => {
  let filterRegex = new RegExp(releaseDropdownVersionFilter, 'i')
  const allUserReleases = releases.filter((r) => r.availability === 'All Users')

  const filterBox = [(
    <ReleaseDropdownFilter type={type} product={product} key='filter'/>
  )]

  let compatibleReleases: ReactElement[] = []
  let incompatibleReleases: ReactElement[] = []

  allUserReleases.filter(r => {
    return filterRegex.test(r.version)
  }).forEach(r => {
    if (r.isCompatible) {
      const onSelect = () => {
        onChooseProductVersion(type, product.slug, r.version)
        disableMinimalServiceTileTargetVersion()
      }
      compatibleReleases.push(<DropdownItem className={'compatible-release'} onSelect={onSelect}
        key={`${type}-${product.slug}-${r.version}=${r.id}`}>
        {r.version}
      </DropdownItem>)
    } else {
      incompatibleReleases.push(
        <DropdownItem className={'incompatible-release'} key={`${type}-${product.slug}-${r.version}=${r.id}`}>
          {r.version}
        </DropdownItem>)
    }
  })

  if (compatibleReleases.length === 0) {
    compatibleReleases.push(
      <DropdownItem className={'incompatible-release'} key={`${type}-${product.slug}-compatible-n/a`}>
        N/A
      </DropdownItem>
    )
  }

  if (error === PlanAlertLevels.ERROR) {
    classNames = classNames.concat(['release-dropdown', 'aligner-item', 'error'])
  } else if (error === PlanAlertLevels.WARNING) {
    classNames = classNames.concat(['release-dropdown', 'aligner-item', 'warning'])
  } else if (error === PlanAlertLevels.INFO) {
    classNames = classNames.concat(['release-dropdown', 'aligner-item', 'info'])
  } else {
    classNames = classNames.concat(['release-dropdown', 'aligner-item'])
  }

  // The function is called twice with 2 different release list
  // The first is to ensure that there are no versions after the initial filtering
  // The second is to ensure that the product really had no releases
  if (shouldDisableDropdown(type, product, allUserReleases)) {
    if (shouldDisableDropdown(type, product, releases)) {
      // There were no versions prior to the filtering so we can just have a NotApplicable version
      return <NotApplicable slug={product.slug} type={type}/>
    } else {
      // There were releases not GAed
      // We display the target version so that the users can see that we will not update this particular tile
      return <NotApplicable slug={product.slug} type={type} text={product.target || 'Not Applicable'}/>
    }
  }

  let titleValue = version ? version : 'Select Version'

  return (
    //@ts-ignore
    <Dropdown
      buttonClassName={classNames}
      title={titleValue}
      id={`${product.slug}-release-${type}-select`}
      onEntered={() => {
        onDropdownEnter()
      }}
      scroll={true}
      itemClassName={'pan'}
    >
      {filterBox}
      <DropdownItem key={`compatible-releases`} header={true}>Compatible Versions</DropdownItem>
      {compatibleReleases}
      <DropdownItem key={`incompatible-releases`} header={true}>Incompatible Versions</DropdownItem>
      {incompatibleReleases}
    </Dropdown>
  )
}

const shouldDisableDropdown = (type: string, product: ChosenProduct, releases: ProductRelease[]) => {
  if (product.lifecycleState === Constants.BETA_LIFECYCLE_STATE) return true
  if (Constants.ADDON_SLUGS.includes(product.slug)) return true

  return (Constants.OUT_OF_SUPPORT_RELEASE_LIFECYCLE_LIST.includes(product.lifecycleState) || !releases.length) &&
    !isCurrentDropdown(type, product)
}

const isCurrentDropdown = (type: string, product: ChosenProduct) => {
  return (product.slug === METRICS_FORWARDER_SLUG || product.slug === 'p-mysql') && type === 'current'
}

const mapStateToProps = (releaseDropdownVersionFilter: string) => {
  return releaseDropdownVersionFilter
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChooseProductVersion: (context: VersionType, product: string, version: string | null) => dispatch(chooseProductVersion(context, product, version)),
  onDropdownEnter: () => dispatch(changeReleaseDropdownVersionFilter('')),
  disableMinimalServiceTileTargetVersion: () => dispatch(selectMinimalServiceTileTargetVersions(false)),
  recalculateCompatibility: () => dispatch(recalculateCompatibility(true))
})

export default connect(mapStateToProps, mapDispatchToProps)(CurrentTargetDropdown)
