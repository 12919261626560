import React from 'react'
import { loadUpgradePlanAsync } from '../actions/load_upgrade_plan'
import { PrimaryButton } from 'pivotal-ui/react/buttons'
import UpgradePlanConfiguration from '../services/upgrade_plan_config'
import { connect } from 'react-redux'
import AnalyticsWrapper from '../services/analytics_wrapper'
import { PlanRepository } from '../repositories/upgrade_plan'
import { ChosenProduct } from '../chosen_products/chosen_products_types'
import { hideRecommendationBubble } from '../actions/diagnostic_report_recommendation'
import { UpgradePortalStore } from '../stores/store'

type ButtonGeneratePlanProps = {
  analyticsWrapper: AnalyticsWrapper
  upgradePlanRepository: PlanRepository
  chosenProducts: ChosenProduct[]
  loadUpgradePlanAsync: (upgradePlanRepository: PlanRepository, upgradeRequestConfiguration: UpgradePlanConfiguration) => void
  hideRecommendationBubble: () => void
  diagnosticReportImported: boolean
}

const ButtonGeneratePlan = ({analyticsWrapper, upgradePlanRepository, chosenProducts, loadUpgradePlanAsync, hideRecommendationBubble, diagnosticReportImported}: ButtonGeneratePlanProps) => {
  const upgradeConfig = new UpgradePlanConfiguration(chosenProducts, diagnosticReportImported)
  return (
    <div className={'txt-r mtl'}>
      <PrimaryButton
        disabled={!upgradeConfig.isValid()}
        id={'calculate-upgrade-steps'}
        onClick={() => {
          analyticsWrapper.fireEvent('Button', 'Click', 'Generate Plan')
          loadUpgradePlanAsync(upgradePlanRepository, upgradeConfig)
          hideRecommendationBubble()
        }}
      >
        Generate Plan
      </PrimaryButton>
    </div>
  )
}

const mapDispatchToProps = {
  loadUpgradePlanAsync,
  hideRecommendationBubble
}

export default connect(({chosenProducts, diagnosticReportImported}: UpgradePortalStore ) => {
  return {
    chosenProducts,
    diagnosticReportImported
  }
}, mapDispatchToProps)(ButtonGeneratePlan)

export { ButtonGeneratePlan }
