export const OPS_MANAGER_MINOR_TARGET_VERSION_CHANGED_ACTION = 'OPS_MANAGER_MINOR_TARGET_VERSION_CHANGED_ACTION'
export const UPDATE_OPS_MANAGER_TARGET_VERSION_ACTION = 'UPDATE_OPS_MANAGER_TARGET_VERSION_ACTION'
export const RECEIVED_PRODUCT_DETAILS_ACTION = 'RECEIVED_PRODUCT_DETAILS'
export const DISMISS_DUPLICATED_PRODUCT_WARNING = 'DISMISS_DUPLICATED_PRODUCT_WARNING'
export const SELECTED_NEW_PRODUCT_ACTION = 'SELECTED_NEW_PRODUCT'
export const RETRIEVE_PRODUCTS = 'RETRIEVE_PRODUCTS'
export const FAILED_TO_FETCH_FOUNDATION_DETAILS_ACTION = 'FAILED_TO_FETCH_FOUNDATION_DETAILS'
export const DISABLE_DOWNLOAD_PDF_BUTTON = 'DISABLE_PDF_DOWNLOAD_BUTTON'
export const ENABLE_DOWNLOAD_PDF_BUTTON = 'ENABLE_PDF_DOWNLOAD_BUTTON'
export const RECALCULATE_COMPATIBILITY_ACTION = 'RECALCULATE_COMPATIBILITY_ACTION'
export const CHECK_NO_AVAILABLE_VERSIONS_ACTION = 'CHECK_NO_AVAILABLE_VERSIONS_ACTION'
export const SELECT_MINIMAL_SERVICE_TILE_TARGET_VERSIONS_ACTION = 'SELECT_MINIMAL_SERVICE_TILE_TARGET_VERSIONS_ACTION'
export const UPDATE_MINIMAL_TARGET_VERSIONS_ACTION = 'UPDATE_MINIMAL_TARGET_VERSIONS_ACTION'
