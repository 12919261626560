import { CHANGE_RELEASE_DROPDOWN_VERSION_FILTER, ChangeReleaseDropdownVersionAction } from '../chosen_products/change_release_dropdown_version_filter'

export default function releaseDropdownVersionFilter(text: string = '', action: ChangeReleaseDropdownVersionAction) {
  switch (action.type) {
  case CHANGE_RELEASE_DROPDOWN_VERSION_FILTER:
    return action.value
  default:
    return text
  }
}
