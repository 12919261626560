import { applyMiddleware, combineReducers, createStore, Middleware } from 'redux'

import thunk from 'redux-thunk'
import logger from 'redux-logger'

import products from '../reducers/products'
import chosenProducts from '../reducers/chosen_products'
import upgradePlan from '../reducers/upgrade_plan'
import upgradePlanLoadingState, { LOADING_STATES } from '../reducers/upgrade_plan_loading_state'
import errorInfo from '../error_info/error_info_reducer'
import pastedOpsmanDiagnostic from '../reducers/pasted_config_text'
import releaseDropdownVersionFilter from '../reducers/release_dropdown_version_filter'
import parsedProductConfig from '../reducers/parsed_product_config'
import serverStatus from '../server_status/server_status_reducer'
import { Product } from '../repositories/products'
import { ChosenProduct } from '../chosen_products/chosen_products_types'
import { Plan } from '../repositories/upgrade_plan'
import { ErrorInfoState } from '../error_info/error_info_types'
import { ProductConfigurationParsed } from '../actions/parse_product_configuration'
import { ServerStatusResponse } from '../server_status/server_status_types'
import opsManagerMinorVersion from '../reducers/ops_manager_minor_version'
import { OpsManagerVersion } from '../repositories/foundation'
import disablePdfDownloadButton from '../reducers/pdf_download_button'
import diagnosticReportImported from '../reducers/diagnostic_report_imported'
import diagnosticReportLoading from '../reducers/diagnostic_report_loading'
import diagnosticReportRecommendation from '../reducers/diagnostic_report_recommendation'
import selectMinimalServiceTileTargetVersions from '../reducers/select_minimal_service_tile_target_versions'
import recommendedOpsmanVersion from '../reducers/recommended_opsman_version'
import { MinorRecommendation } from '../actions/diagnostic_report_recommendation'

export type UpgradePortalStore = {
  products: Product[]
  chosenProducts: ChosenProduct[]
  upgradePlan: Plan
  upgradePlanLoadingState: LOADING_STATES
  errorInfo: ErrorInfoState
  pastedOpsmanDiagnostic: string
  releaseDropdownVersionFilter: string
  parsedProductConfig: ProductConfigurationParsed
  serverStatus: ServerStatusResponse
  opsManagerMinorVersion: OpsManagerVersion
  disablePdfDownloadButton: boolean
  diagnosticReportImported: boolean
  diagnosticReportLoading: boolean
  diagnosticReportRecommendation: MinorRecommendation
  selectMinimalServiceTileTargetVersions: boolean
  recommendedOpsmanVersion: OpsManagerVersion
}

const rootReducer = combineReducers<UpgradePortalStore>({
  products,
  chosenProducts,
  upgradePlan,
  upgradePlanLoadingState,
  errorInfo,
  pastedOpsmanDiagnostic,
  releaseDropdownVersionFilter,
  parsedProductConfig,
  serverStatus,
  opsManagerMinorVersion: opsManagerMinorVersion,
  disablePdfDownloadButton,
  diagnosticReportImported,
  diagnosticReportLoading,
  diagnosticReportRecommendation,
  selectMinimalServiceTileTargetVersions,
  recommendedOpsmanVersion
})

let middleware: Middleware[] = [thunk]
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger]
}
const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore)

const store = createStoreWithMiddleware(rootReducer)

export default store
