import React, { ReactComponentElement } from 'react'
import { Icon } from 'pivotal-ui/react/iconography'
import * as Constants from '../constants/constants'
import { TKG_SLUG } from '../constants/constants'
import { ChosenProduct, permittedOutOfSupportProductSlugs } from './chosen_products_types'

export const NoLongerSupportedWarning = () => (
  <div className='man type-sm'>
    <Icon src="warning" verticalAlign="baseline" className='fill-decorative-yellow' /> No longer supported
  </div>
)

export const NoPublicReleasesWarning = () => (
  <div className='man type-sm'>
    <Icon src="warning" verticalAlign="baseline" className='fill-decorative-yellow' />  All releases for this product are admins-only and cannot be downloaded at this time. Upgrade Planner does not currently support products without any publicly-available releases.
  </div>
)

export const SlugNotFoundWarning = ({slug}: Partial<ChosenProduct>) => (
  <div className='man type-sm'>
    <Icon src="warning" verticalAlign="baseline" className='fill-decorative-yellow' /> {slug} not recognized. If this tile is provided by Tanzu Network, try manually adding it below. If this is a tile you developed, ensure that this product is compatible with your target state.
  </div>
)

export const BetaProductWarning = ({name}: Partial<ChosenProduct>) => (
  <div className='man type-sm'>
    <Icon src="warning" verticalAlign="baseline" className='fill-decorative-yellow' /> {name} is a beta product and not supported by Upgrade Planner.
  </div>
)

export const AddonProductWarning = ({name}: Partial<ChosenProduct>) => (
  <div className='man type-sm'>
    <Icon src="warning" verticalAlign="baseline" className='fill-decorative-yellow' /> {name} is an add-on and not supported by Upgrade Planner.
  </div>
)

export const GeneralUnsupportedWarning = ({name}: Partial<ChosenProduct>) => (
  <div className='man type-sm'>
    <Icon src="warning" verticalAlign="baseline" className='fill-decorative-yellow' /> {name} is not supported by Upgrade Planner.
  </div>
)


type ProductDetailProps = {
  product: ChosenProduct,
  warningValue?: null | ReactComponentElement<any>
}

export const ProductDetail = ({product, warningValue = null}: ProductDetailProps) => {
  const rowText = product.name ? product.name : product.slug
  return (
    <div id={product.slug + '-row-label'}>
      <p className='man type-lg'>{rowText}</p>
      {warningValue}
    </div>
  )
}

// There is probably a better place for this
function calculateWarningValue(product: ChosenProduct): null | ReactComponentElement<any> {
  const { lifecycleState, slug, name } = product

  if(product.slug === TKG_SLUG) {
    return <GeneralUnsupportedWarning name={product.name}/>
  }

  if(typeof(lifecycleState) !== 'undefined' && Constants.OUT_OF_SUPPORT_RELEASE_LIFECYCLE_LIST.includes(lifecycleState) && !permittedOutOfSupportProductSlugs.includes(product.slug)) {
    return <NoLongerSupportedWarning/>
  }

  if (product.failedToFetch) {
    return <SlugNotFoundWarning slug={slug}/>
  }

  if (lifecycleState === Constants.BETA_LIFECYCLE_STATE) {
    return <BetaProductWarning name={name}/>
  }

  if (Constants.ADDON_SLUGS.includes(product.slug)) {
    return <AddonProductWarning name={name}/>
  }

  if (product.releases === undefined) {
    return null
  }

  if (product.releases.length === 0) {
    return <NoPublicReleasesWarning/>
  }

  return null
}

type WrappedProductDetailProps = {
  product: ChosenProduct
}

const WrappedProductDetail = ({product}: WrappedProductDetailProps) => (
  <ProductDetail product={product} warningValue={calculateWarningValue(product)}/>
)

export default WrappedProductDetail
