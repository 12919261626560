import React from 'react'

import { Icon } from 'pivotal-ui/react/iconography'
import { FlexCol, Grid } from 'pivotal-ui/react/flex-grids'
import { connect } from 'react-redux'
import { UpgradePortalStore } from '../stores/store'

type LoadProductsSpinnerProps = {
  diagnosticReportLoading: boolean
}

const LoadProductsSpinner = (props: LoadProductsSpinnerProps) => {
  if (!props.diagnosticReportLoading) return null

  return (
    <div className={'loading-spinner'}>
      <div className={'overlay'}/>
      <div className={'spinner-bg'}>
        <div className={'box'}>
          <Grid>
            <FlexCol col={2} fixed/>
            <FlexCol col={22}>
              <h3 className={'txt-l'}>Adding products from your report</h3>
            </FlexCol>
            <FlexCol col={2} fixed/>
            <FlexCol col={22}>
              <Icon src={'spinner-lg'} style={{color: 'green', fontSize: '96px'}}/>
            </FlexCol>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export { LoadProductsSpinner }

export default connect(
  (state: UpgradePortalStore) => {
    return {
      diagnosticReportLoading: state.diagnosticReportLoading
    }
  }
)(LoadProductsSpinner)