import React, {ReactNode} from 'react'

interface IProps {
  children: ReactNode
  header?: boolean
  divider?: boolean
  onSelect?: () => void
  role?: string
  className?: string
}

const DropdownItem = ({role, children, header, divider, onSelect}: IProps)  => {

  if (header) return (<div role="heading" className="dropdown-header ptl phl">{children}</div>)
  if (divider) return (<div role="separator" className="divider"/>)

  return (
    <div onClick={onSelect} role={role} className={'phl pvm'}>
      {children}
    </div>
  )
}

export {DropdownItem}