import { RECOMMEND_OPSMAN_VERSION, RecommendOpsmanVersionAction } from '../actions/recommend_opsman_version'
import { OpsManagerVersion } from '../repositories/foundation'


export default function recommendedOpsmanVersion(state: OpsManagerVersion = OpsManagerVersion.NO_VERSION, action: RecommendOpsmanVersionAction): OpsManagerVersion {
  switch (action.type) {
  case RECOMMEND_OPSMAN_VERSION:
    return action.version
  default:
    return state
  }
}
