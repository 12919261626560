import { OpsManagerVersion } from '../repositories/foundation'

export const RECOMMEND_OPSMAN_VERSION = 'RECOMMEND_OPSMAN_VERSION'

export type RecommendOpsmanVersionAction = {
  type: typeof RECOMMEND_OPSMAN_VERSION,
  version: OpsManagerVersion
}

export function recommendOpsmanVersion(version: OpsManagerVersion): RecommendOpsmanVersionAction {
  return {
    type: RECOMMEND_OPSMAN_VERSION,
    version: version
  }
}
