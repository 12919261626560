import { LOADING_STATES } from '../reducers/upgrade_plan_loading_state'

export const CHANGE_UPGRADE_PLAN_LOADING_STATE = 'CHANGE_UPGRADE_PLAN_LOADING_STATE'

export type ChangeUpgradePlanLoadingStateAction = {
  type: typeof CHANGE_UPGRADE_PLAN_LOADING_STATE,
  value: LOADING_STATES
}

export const changeUpgradePlanLoadingState = (state: LOADING_STATES): ChangeUpgradePlanLoadingStateAction => {
  return {
    type: CHANGE_UPGRADE_PLAN_LOADING_STATE,
    value: state
  }
}
