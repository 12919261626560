export const RECOMMENDED_VERSION = 'RECOMMENDED_VERSION'
export const NO_RECOMMENDED_VERSION = 'NO_RECOMMENDED_VERSION'
export const HIDE_RECOMMENDATION_BUBBLE = 'HIDE_RECOMMENDATION_BUBBLE'

export enum MinorRecommendation {
  NO_RECOMMENDATION,
  RECOMMENDATION_SHOW_BUBBLE,
  RECOMMENDATION_HIDE_BUBLE
}

export type DiagnosticReportRecommendationAction = {
  type: typeof RECOMMENDED_VERSION | typeof NO_RECOMMENDED_VERSION | typeof HIDE_RECOMMENDATION_BUBBLE
}

export function recommendedVersion(): DiagnosticReportRecommendationAction {
  return {
    type: RECOMMENDED_VERSION
  }
}

export function didNotRecommendAVersion(): DiagnosticReportRecommendationAction {
  return {
    type: NO_RECOMMENDED_VERSION
  }
}

export function hideRecommendationBubble(): DiagnosticReportRecommendationAction {
  return {
    type: HIDE_RECOMMENDATION_BUBBLE
  }
}
