import { SIGNAL_SERVER_DOWN } from './server_status_types'

const signalServerDown = (errorMessage: string): any => {
  return {
    type: SIGNAL_SERVER_DOWN,
    message: errorMessage
  }
}

export default signalServerDown
