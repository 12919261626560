import { Divider } from 'pivotal-ui/react/dividers'
import { UnorderedList } from 'pivotal-ui/react/lists'
import React, { ReactElement } from 'react'
import { StepAlert } from './step_alert'
import { WarningsSection } from './warnings_section'
import { InfosSection } from './infos_section'
import { Phase, Plan, PlanRepository, Step as StepType } from '../../repositories/upgrade_plan'
import AnalyticsWrapper from '../../services/analytics_wrapper'
import { UpgradePortalStore } from '../../stores/store'
import { connect } from 'react-redux'
import { PlanOverview } from './plan_overview'
import { Caption, Table, Tbody, Td, Th, Thead, Tr } from 'pivotal-ui/react/table'
import { PlanStepTypes } from './upgrade_steps_types'
import { isOpsMan } from '../../repositories/foundation'
import DropdownExportPlan from '../dropdown_export_plan'


type UpgradePhaseProps = {
  phase: Phase,
  phaseNumber: number
}

type StepTableRow = {
  stepName: string
  productName?: ReactElement
  stemcell?: ReactElement
  releaseNote?: ReactElement
  eogsDate?: string
}

const UpgradePhase = ({phase, phaseNumber}: UpgradePhaseProps) => (
  <UnorderedList unstyled key={`upgrade-plan-composite-step-${phaseNumber + 1}`}
    id={`upgrade-plan-composite-step-${phaseNumber + 1}`}>
    <Table className={'pui-table--borders-none pui-table--tr-hover mbn'}>
      <Caption className={'ptxxl h3'}>{`Phase ${phaseNumber + 1}`}</Caption>
      <Thead>
        <Tr>
          <Th className={'width-128 pts pbs'}>Step</Th>
          <Th className={'pts pbs'}>Product</Th>
          <Th className={'width-208 pts pbs'}>Stemcell</Th>
          <Th className={'width-102 pts pbs'}>Release Notes</Th>
          <Th className={'width-82 pts pbs'}>EOGS Date</Th>
        </Tr>
      </Thead>
      <Tbody className={'type-sm'}>
        {phase.map((step: StepType, subStepCount) => {
          let stepRow = generateStepRowInformation(step, subStepCount + 1)
          return <Tr id={`phase-${phaseNumber + 1}-step-${subStepCount + 1}`}
            key={`phase-${phaseNumber + 1}-step-${subStepCount + 1}`} className={'step txt-t'}>
            <Td>
              {stepRow.stepName}
            </Td>
            <Td>
              {stepRow.productName}
            </Td>
            <Td>
              {stepRow.stemcell}
            </Td>
            <Td>
              {stepRow.releaseNote}
            </Td>
            <Td>
              {stepRow.eogsDate}
            </Td>
          </Tr>
        })}
      </Tbody>
    </Table>
  </UnorderedList>
)

function generateStepRowInformation(step: StepType, subStepCount: number): StepTableRow {
  let stepRow: StepTableRow
  let className
  switch (step.type) {
  case PlanStepTypes.RELEASE:
    let verb = 'Import'
    if (isOpsMan(step.value.slug)) {
      verb = 'Upgrade'
    }
    className = step.value.alerts.length > 0 ? 'release-with-warning' : 'release'
    stepRow = {
      stepName: `${subStepCount}. ${verb}`,
      productName: <div className={className}>
        <div>
          <a href={step.value.link} target={'_new'}>{step.value.name} {step.value.version}</a>
        </div>
        {step.value.alerts.length > 0 && <StepAlert alerts={step.value.alerts}/>}
      </div>,
      releaseNote: <a href={step.value.releaseNotesLink} target={'_new'}>Release Notes</a>,
      eogsDate: step.value.endOfSupportDate,
      stemcell: <a href={step.value.stemcell?.link} target={'_new'}>{step.value.stemcell?.message}</a>
    }
    break
  case PlanStepTypes.DELETE:
    className = step.value.alerts.length > 0 ? 'release-with-warning' : 'release'
    stepRow = {
      stepName: `${subStepCount}. Delete`,
      productName: <div className={className}>
        <div>
          <a href={step.value.link} target={'_new'}>{step.value.name} {step.value.version}</a>
        </div>
        {step.value.alerts.length > 0 && <StepAlert alerts={step.value.alerts}/>}
      </div>,
      releaseNote: <div>N/A</div>,
      eogsDate: 'N/A',
      stemcell: <div>N/A</div>
    }
    break
  case PlanStepTypes.INFO:
    stepRow = {
      stepName: `${subStepCount}. Read`,
      productName: <a href={step.value.link} target={'_new'}>{step.value.text}</a>
    }
    break
  case PlanStepTypes.APPLY_CHANGES:
    stepRow = {
      stepName: `${subStepCount}. Apply Changes`
    }
  }
  return stepRow
}

type SuccessPanelProps = {
  analyticsWrapper: AnalyticsWrapper
  upgradePlanRepository: PlanRepository
  upgradePlan: Plan
}

const SuccessPanel = ({analyticsWrapper, upgradePlanRepository, upgradePlan}: SuccessPanelProps) => (
  <div className={'pvxxl success'}>
    <div className={'pvxxl'} id='copyable-plan'>
      <div>
        <h2><a href={'#plan'}>Your Upgrade Plan</a></h2>
        <Divider/>
        <div className={'mbxxl'}>
          To upgrade products on your foundation, follow steps in this order. Upgrades listed in a single phase should
          be
          configured in the specified order before applying changes.
        </div>
        <div className='mbxxxl'>
          Please review the release notes, breaking changes, and upgrade documentation for the applications in the
          upgrade
          plan.
          Note that some products and upgrades may cause downtime.
        </div>
        <PlanOverview upgradePlan={upgradePlan}/>
        <WarningsSection
          alerts={upgradePlan.alerts}
        />
        <InfosSection
          alerts={upgradePlan.alerts}
        />
      </div>
      <UnorderedList unstyled id='upgrade-plan-phases'>
        {upgradePlan.phases.map((phase, index) => <UpgradePhase key={index} phase={phase} phaseNumber={index}/>)}
      </UnorderedList>
    </div>
    <div className={'txt-l'}>
      <DropdownExportPlan upgradePlanRepository={upgradePlanRepository} analyticsWrapper={analyticsWrapper}/>
    </div>
  </div>
)

const mapStateToProps = ({disablePdfDownloadButton}: UpgradePortalStore) => {
  return {disablePdfDownloadButton}
}

export default connect(mapStateToProps)(SuccessPanel)

export { SuccessPanel }
