import { Product, ProductRepository } from '../repositories/products'
import { Dispatch } from 'redux'
import { RETRIEVE_PRODUCTS } from './actions'

export type RetrieveProductAction = {
    type: typeof RETRIEVE_PRODUCTS
    products: Product[]
}

const retrieveProducts = (products: Product[]) => {
  return {
    type: RETRIEVE_PRODUCTS,
    products
  }
}

const retrieveProductsAsync = (productRepository: ProductRepository) => {
  const retrieveProductsThunk = (dispatch: Dispatch) => {
    return productRepository.getProducts(dispatch)
      .then((products: Product | Product[]) => {
        if (products === undefined) return true //Todo fix this
        if (!Array.isArray(products)) {
          products = [products]
        }

        dispatch(retrieveProducts(products))
        return true
      })
  }

  return retrieveProductsThunk
}

export default retrieveProductsAsync
