import { DownloadPdfButtonAction } from '../actions/pdf_download_button'
import { DISABLE_DOWNLOAD_PDF_BUTTON, ENABLE_DOWNLOAD_PDF_BUTTON } from '../actions/actions'

export default function disablePdfDownloadButton(state: boolean = false, action: DownloadPdfButtonAction): boolean {
  switch (action.type) {
  case DISABLE_DOWNLOAD_PDF_BUTTON:
    return true
  case ENABLE_DOWNLOAD_PDF_BUTTON:
    return false
  default:
    return state
  }
}
