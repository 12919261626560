import React from 'react'
import { LOADING_STATES } from '../../reducers/upgrade_plan_loading_state'
import { connect } from 'react-redux'
import SuccessPanel from './success_panel'
import { FailurePanel } from './failure_panel'
import { WaitingPanel } from './waiting_panel'
import AnalyticsWrapper from '../../services/analytics_wrapper'
import { ErrorInfoState } from '../../error_info/error_info_types'
import { Plan, PlanRepository, PlanSupportStatusUtil } from '../../repositories/upgrade_plan'
import { UpgradePortalStore } from '../../stores/store'

type UpgradeStepsPanelProps = {
  upgradePlan: Plan
  loadingState: LOADING_STATES
  errorInfo: ErrorInfoState
  analyticsWrapper: AnalyticsWrapper
  upgradePlanRepository: PlanRepository
}

const UpgradeStepsPanel = ({upgradePlan, loadingState, errorInfo, upgradePlanRepository, analyticsWrapper}: UpgradeStepsPanelProps) => {
  switch (loadingState) {
  case LOADING_STATES.DEFAULT:
  case LOADING_STATES.PENDING:
    return <WaitingPanel/>

  case LOADING_STATES.FAILURE:
    setTimeout(scrollPlanToTopOfScreen, 200) // setTimeout used to ensure page if fully loaded

    analyticsWrapper.fireEvent('Display', 'Load', `Plan Result - ${errorInfo.type}`)
    return <FailurePanel
      errorInfo={errorInfo}
    />

  case LOADING_STATES.SUCCESS:
    setTimeout(scrollPlanToTopOfScreen, 200) // setTimeout used to ensure page if fully loaded
    analyticsWrapper.fireEvent(
      'Display',
      'Load',
      'Plan Result - Success',
      {planSupportStatus: PlanSupportStatusUtil.toString(upgradePlan.supportStatus)})
    return <SuccessPanel
      analyticsWrapper={analyticsWrapper}
      upgradePlanRepository={upgradePlanRepository}
      upgradePlan={upgradePlan}
    />

    // no default
  }
}

const scrollPlanToTopOfScreen = () => {
  const element = document.querySelector('.plan')
  if (element === null) {
    return
  }

  const topPos = element.getBoundingClientRect().top + window.pageYOffset

  window.scrollTo({
    top: topPos,
    behavior: 'smooth'
  })
}

export { UpgradeStepsPanel }

export default connect(
  (state: UpgradePortalStore) => {
    return {
      upgradePlan: state.upgradePlan,
      loadingState: state.upgradePlanLoadingState,
      errorInfo: state.errorInfo
    }
  }
)(UpgradeStepsPanel)
