import { Plan, PlanRepository } from '../repositories/upgrade_plan'

export function downloadPdf(upgradePlanRepository: PlanRepository, upgradePlan: Plan): Promise<null> {
  let date = new Date()
  return upgradePlanRepository.createPdf(upgradePlan, date).then((pdfBlob) => {
    let link = document.createElement('a')
    let dateStr = date.getFullYear().toString() +
      '-' + (date.getMonth() + 1).toString() +
      '-' + date.getDate().toString()
    link.href = window.URL.createObjectURL(pdfBlob)
    link.download = `Upgrade-Plan-${dateStr}.pdf`
    link.target = '_blank'

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)

    return Promise.resolve(null)
  }).catch(() => {
    return Promise.reject(new Error('PDF creation failure'))
  })
}
