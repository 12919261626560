import { UpdateOpsManagerMinorTargetVersionAction } from '../actions/select_ops_manager_minor_target_version'
import { UPDATE_OPS_MANAGER_TARGET_VERSION_ACTION } from '../actions/actions'
import { OpsManagerVersion } from '../repositories/foundation'

const initialVersion: OpsManagerVersion = OpsManagerVersion.N

export default function opsManagerMinorVersionReducer(version: OpsManagerVersion = initialVersion, action: UpdateOpsManagerMinorTargetVersionAction): OpsManagerVersion {
  switch (action.type) {
  case UPDATE_OPS_MANAGER_TARGET_VERSION_ACTION:
    return action.version
  default:
    return version
  }
}