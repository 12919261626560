import React from 'react'

import { connect } from 'react-redux'

import { DefaultButton } from 'pivotal-ui/react/buttons'
import { Icon } from 'pivotal-ui/react/iconography'

import removeProductFromConfiguration from './remove_product_from_configuration_action'
import { Dispatch } from 'redux'
import { ChosenProduct } from './chosen_products_types'
import { recalculateCompatibility } from '../actions/recalculate_compatibility'

type RemoveProductButtonProps = {
  product: ChosenProduct,
  removeProduct: (product: ChosenProduct) => void
  recalculateCompatibility: () => void
}

export const RemoveProductButton = ({product, removeProduct, recalculateCompatibility}: RemoveProductButtonProps) => (
  <DefaultButton
    id={`remove-product-${product.slug}`}
    onClick={() => {
      removeProduct(product)
      if(product.pivotalPlatform) {
        recalculateCompatibility()
      }
    }}
    flat
    aria-label={`Remove ${product.name}`}
    iconOnly>
    <Icon src={'close'}/>
  </DefaultButton>
)

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    removeProduct: (product: ChosenProduct) => dispatch(removeProductFromConfiguration(product)),
    recalculateCompatibility: () => dispatch(recalculateCompatibility(true))
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(RemoveProductButton)
