import React from 'react'
import LoadProductsSpinner from './components/load_products_spinner'
import Footer from './components/footer'
import retrieveProductsAsync from './actions/retrieve_products'
import { connect } from 'react-redux'
import AppBody from './components/app_body'
import { UpgradePlannerHeader } from './components/upgrade_planner_header'
import { Product, ProductRepository } from './repositories/products'
import AnalyticsWrapper from './services/analytics_wrapper'
import { PlanRepository } from './repositories/upgrade_plan'
import { ChosenProduct } from './chosen_products/chosen_products_types'
import { ServerStatusResponse } from './server_status/server_status_types'
import { UpgradePortalStore } from './stores/store'
import { Dispatch } from 'redux'
import { FoundationRepository } from './repositories/foundation'
import { retrieveProductsFromUrlAsync } from './actions/retrieve_products_from_url'
import { retrieveDefaultProductsAsync } from './actions/retrieve_default_products'
import PlanLoadingSpinner from "./components/plan_loading_spinner"


type AppProps = {
  products: Product[]
  analyticsWrapper: AnalyticsWrapper
  upgradePlanRepository: PlanRepository
  productRepository: ProductRepository
  chosenProducts: ChosenProduct[]
  serverStatus: ServerStatusResponse
  retrieveProductsAsync: (productRepository: ProductRepository) => Promise<boolean>
  dispatch: Dispatch
  foundationRepository: FoundationRepository
  retrieveProductsFromUrlAsync: (productRepository: ProductRepository) => Promise<any>
  retrieveDefaultProductsAsync: (productRepository: ProductRepository) => Promise<any>
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    this.props.retrieveProductsFromUrlAsync(this.props.productRepository).catch(() => {
      this.props.retrieveDefaultProductsAsync(this.props.productRepository).catch(() => {
      })
    }).catch(() => {
    })
    this.props.retrieveProductsAsync(this.props.productRepository).catch(() => {
    }) // Already handled in the repository
  }

  render() {
    return (
      <div className={'page-width'}>
        <UpgradePlannerHeader/>

        <div className='container'>
          <AppBody
            analyticsWrapper={this.props.analyticsWrapper}
            upgradePlanRepository={this.props.upgradePlanRepository}
            productRepository={this.props.productRepository}
            serverStatus={this.props.serverStatus}
            products={this.props.products}
            foundationRepository={this.props.foundationRepository}
          />
          <PlanLoadingSpinner/>
          <LoadProductsSpinner/>
        </div>
        <Footer/>
      </div>
    )
  }
}

const mapStateToProps = ({products, chosenProducts, serverStatus}: UpgradePortalStore) => {
  return {products, chosenProducts, serverStatus}
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveProductsAsync: (productRepository: ProductRepository) => retrieveProductsAsync(productRepository)(dispatch),
  dispatch: dispatch,
  retrieveProductsFromUrlAsync: (productRepository: ProductRepository) => retrieveProductsFromUrlAsync(productRepository)(dispatch),
  retrieveDefaultProductsAsync: (productRepository: ProductRepository) => retrieveDefaultProductsAsync(productRepository)(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
