export const CHOOSE_PRODUCT_VERSION_ACTION = 'CHOOSE_PRODUCT_VERSION'

export type VersionType = 'target' | 'current'

export type ChooseProductVersionAction = {
  type: typeof CHOOSE_PRODUCT_VERSION_ACTION,
  product: string,
  currentVersion: string | null,
  targetVersion: string | null
}

export const chooseProductVersion = (context: VersionType, product: string, version: string | null): ChooseProductVersionAction => {
  if (context === 'target') {
    return {
      type: CHOOSE_PRODUCT_VERSION_ACTION,
      product: product,
      currentVersion: null,
      targetVersion: version
    }
  }

  return {
    type: CHOOSE_PRODUCT_VERSION_ACTION,
    product: product,
    currentVersion: version,
    targetVersion: null
  }
}

export const chooseProductVersions = (product: string, currentVersion: string | null, targetVersion: string | null): ChooseProductVersionAction => {
  return {
    type: CHOOSE_PRODUCT_VERSION_ACTION,
    product: product,
    currentVersion: currentVersion,
    targetVersion: targetVersion
  }
}
