import { Dispatch } from 'redux'
import { ProductRepository } from '../repositories/products'
import selectNewProduct from '../chosen_products/select_new_product_action'
import { RecalculateCompatibilityAction } from './recalculate_compatibility'


export const DEFAULT_SELECTED_PRODUCTS = ['elastic-runtime', 'ops-manager']

export function retrieveDefaultProductsAsync(productRepository: ProductRepository) {
  return function (dispatch: Dispatch): Promise<(void | RecalculateCompatibilityAction)[]> {
    return Promise.all(DEFAULT_SELECTED_PRODUCTS.map((slug: string) => {
      return selectNewProduct({
        slug: slug,
        name: ''
      }, productRepository)(dispatch)
    }))
  }
}
