import receivedProductDetails from './received_product_details_action'
import { failedToFetchProductDetails } from './failed_to_fetch_product_details_action'
import { Product, ProductDetails, ProductRepository } from '../repositories/products'
import { Dispatch } from 'redux'
import signalServerDown from '../server_status/signal_server_down_action'
import { SELECTED_NEW_PRODUCT_ACTION } from '../actions/actions'
import { recalculateCompatibility } from '../actions/recalculate_compatibility'

export type SelectNewProductAction = {
  type: typeof SELECTED_NEW_PRODUCT_ACTION,
  value: Product | ProductDetails
}

const selectNewProduct = (product: Product | ProductDetails, productRepository: ProductRepository) => (dispatch: Dispatch) => {
  dispatch({
    type: SELECTED_NEW_PRODUCT_ACTION,
    value: product
  })

  return productRepository
    .getProductDetails(dispatch, product.slug)
    .then(productDetails => dispatch(receivedProductDetails(product.slug, productDetails)))
    .then(() => dispatch(
      recalculateCompatibility(false)
    ))
    .catch((error) => {
      if (error !== undefined && error.message === 'Failed to fetch') {
        dispatch(signalServerDown('Server is down at the moment.'))
      } else {
        dispatch(failedToFetchProductDetails(product.slug))
      }
    })
}

export default selectNewProduct
