export const SIGNAL_SERVER_DOWN = 'SIGNAL_SERVER_DOWN'
export const SIGNAL_PIVNET_DOWN = 'SIGNAL_PIVNET_DOWN'

interface SignalServerDownAction {
  type: typeof SIGNAL_SERVER_DOWN,
  message: string
}

interface SignalPivnetDownAction {
  type: typeof SIGNAL_PIVNET_DOWN,
  message: string
}

export enum StatusType {
  HEALTHY = 'HEALTHY',
  SERVER_DOWN = 'SERVER_DOWN',
  PIVNET_DOWN = 'PIVNET_DOWN'
}

export interface ServerStatusResponse {
  type: StatusType,
  message: string
}

export type ServerStatusActionTypes = SignalServerDownAction | SignalPivnetDownAction