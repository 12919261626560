import { UPServerFetcher } from '../services/up_server_fetcher'
import { Dispatch } from 'redux'
import { PlanStepTypes } from '../components/upgrade_steps_panel/upgrade_steps_types'

export interface ProductPlan {
  slug: string
  name: string
  current: string | null
  target: string | null
  logoUrl?: string
  presentInInitialFoundation: boolean
  pivotalPlatform: boolean
}

export interface ProductPlanRequest {
  slug: string
  current: string | null
  target: string | null
}

export interface PlanRepository {
  getPlan(dispatch: Dispatch, productsConfiguration: ProductPlanRequest[]): Promise<Plan>

  createPdf(upgradePlan: Plan, date: Date): Promise<Blob>
}

export type InfoValueStep = {
  text: string,
  link: string
}

export type ReleaseAlert = {
  message: string,
  link: string
}

export type ReleaseStemcell = {
  message: string,
  link: string
}

export type ReleaseValueStep = {
  name: string,
  slug: string
  id: number,
  version: string,
  endOfSupportDate: string,
  link: string,
  releaseNotesLink: string,
  alerts: ReleaseAlert[],
  stemcell?: ReleaseStemcell
}

export type DeleteValueStep = {
  name: string,
  slug: string
  id: number,
  version: string,
  link: string,
  alerts: ReleaseAlert[]
}

export type ReleaseStep = {
  type: typeof PlanStepTypes.RELEASE,
  value: ReleaseValueStep
}

export type DeleteStep = {
  type: typeof PlanStepTypes.DELETE,
  value: DeleteValueStep
}

export type InfoStep = {
  type: typeof PlanStepTypes.INFO,
  value: InfoValueStep
}

export type ApplyChangesStep = {
  type: typeof PlanStepTypes.APPLY_CHANGES
}

export type Step = ReleaseStep | DeleteStep | InfoStep | ApplyChangesStep

export type Phase = Step[]

export type Alert = {
  name: string,
  type: string,
  level: string,
  compatibleVersion: string
  currentVersion: string
  message: string
}

export enum PlanSupportStatus {
  IntoSupport = 'IntoSupport',
  MaintainSupport = 'MaintainSupport',
  OutOfSupport = 'OutOfSupport'
}

export type Plan = {
  phases: Phase[],
  alerts: Alert[],
  foundation: ProductPlan[],
  supportStatus: PlanSupportStatus
}

export class PlanSupportStatusUtil {
  static toString(status: PlanSupportStatus): string {
    switch (status) {
    case PlanSupportStatus.IntoSupport:
      return 'Into Support'
    case PlanSupportStatus.MaintainSupport:
      return 'Already in Support'
    case PlanSupportStatus.OutOfSupport:
      return 'Staying Out of Support'
    }
  }
}

export class UpgradePlanRepository implements PlanRepository {
  private upServerFetcher: UPServerFetcher

  constructor(upServerFetcher: UPServerFetcher) {
    this.upServerFetcher = upServerFetcher
  }

  getPlan(dispatch: Dispatch, productsConfiguration: ProductPlanRequest[]): Promise<Plan> {
    const request = this.makePlanRequest(productsConfiguration)

    return this.upServerFetcher.fetch(request)
      .then((response: Response): Plan | any => {
        if (response.status > 399) {
          return Promise.reject(response)
        } else return response.json()
      }).catch((e) => {
        return Promise.reject(e)
      })
  }

  createPdf(upgradePlan: Plan, date: Date): Promise<Blob> {
    const request = this.makePdfRequest(upgradePlan, date)

    return this.upServerFetcher.fetch(request)
      .then((response: Response): Blob | any => {
        if (response.status > 399) {
          return Promise.reject(response)
        } else return response.blob()
      }).catch((e) => {
        return Promise.reject(e)
      })
  }

  private makePdfRequest(upgradePlan: Plan, date: Date) {
    const body = JSON.stringify({
      date: date.getFullYear().toString() +
        '/' + (date.getMonth() + 1).toString() +
        '/' + date.getDate().toString(),
      ...upgradePlan
    })

    const options: RequestInit = {
      method: 'POST',
      headers: this.makeHeaders(),
      mode: 'cors',
      cache: 'default',
      body
    }

    const url = this.upServerFetcher.generateUrl('/v1/pdf')
    const request = new Request(
      url,
      options
    )

    return request
  }


  private makePlanRequest(productDetails: ProductPlanRequest[]) {
    const body = JSON.stringify({
      foundationConfiguration: productDetails
    })

    const options: RequestInit = {
      method: 'POST',
      headers: this.makeHeaders(),
      mode: 'cors',
      cache: 'default',
      body
    }

    const url = this.upServerFetcher.generateUrl('/v1/plan')
    const request = new Request(
      url,
      options
    )

    return request
  }

  private makeHeaders() {
    const requestHeaders = new Headers()

    requestHeaders.append('Accept', 'application/json')
    requestHeaders.append('Content-Type', 'application/json')

    return requestHeaders
  }
}
