export const OUT_OF_SUPPORT_RELEASE_LIFECYCLE_LIST = ['Retired', 'End of Availability', 'Unavailable']
export const BETA_LIFECYCLE_STATE = 'Beta'
export const MYSQL_V1_SLUG = 'p-mysql'
export const HEALTHWATCH_SLUG = 'p-healthwatch'
export const SPRING_CLOUD_SERVICE_V1_V2_SLUG = 'p-spring-cloud-services'
export const SPRING_CLOUD_SERVICE_V3_SLUG = 'p_spring-cloud-services'
export const METRICS_FORWARDER_SLUG = 'p-metrics-forwarder'
export const TKGI_SLUG = 'pivotal-container-service'
export const TKG_SLUG = 'tkg'
export const TAS_SLUG = 'elastic-runtime'
export const OPSMAN_SLUG = 'ops-manager'

export const UPGRADE_PLANNER_USERNAME_FROM_CI = 'tas-operability.pdl@broadcom.com'

export const ADDON_SLUGS = ['p-ipsec-addon']
