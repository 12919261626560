import { ClearProductsAction } from './clear_products_action'
import { SelectNewProductAction } from './select_new_product_action'
import { ReceivedProductDetailsAction } from './received_product_details_action'
import { FailedToFetchProductDetailsAction } from './failed_to_fetch_product_details_action'
import { RemoveProductFromConfigurationAction } from './remove_product_from_configuration_action'
import { ChooseProductVersionAction } from './choose_product_version_action'
import { ProductDetails } from '../repositories/products'
import { DismissDuplicatedProductWarningAction } from '../actions/dismiss_duplicated_product_warning'
import { OpsManagerMinorTargetVersionChangedAction } from '../actions/select_ops_manager_minor_target_version'
import { FailedToFetchFoundationDetailsAction } from '../actions/add_products_from_diagnostic_report'
import { RecalculateCompatibilityAction } from '../actions/recalculate_compatibility'
import { UpdateMinimalTargetVersionsAction } from '../actions/select_minimal_service_tile_target_versions'
import { SemverInterval } from '../services/semver'

export enum PlanAlertLevels {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  NONE = 'NONE'
}

export enum PlanWarningTypes {
  METRICS_FORWARDER = 'METRICS_FORWARDER',
  NO_RELEASES = 'NO_RELEASES',
  UNKNOWN_SLUG = 'UNKNOWN_SLUG',
  OUT_OF_SUPPORT = 'OUT_OF_SUPPORT'
}

export enum PlanInfoTypes {
  INCOMPATIBLE_CURRENT_VERSION = 'INCOMPATIBLE_CURRENT_VERSION'
}

export type ChosenProductErrors = {
  current: PlanAlertLevels,
  target: PlanAlertLevels
}

export interface DropdownHighlightTypes {
  currentType: PlanAlertLevels
  targetType: PlanAlertLevels
}

export const alertToDropdownHighlights: { [string: string]: DropdownHighlightTypes } = {}
alertToDropdownHighlights[PlanWarningTypes.METRICS_FORWARDER] = {
  currentType: PlanAlertLevels.NONE,
  targetType: PlanAlertLevels.WARNING
}
alertToDropdownHighlights[PlanInfoTypes.INCOMPATIBLE_CURRENT_VERSION] = {
  currentType: PlanAlertLevels.INFO,
  targetType: PlanAlertLevels.NONE
}

export type DependencyError = {
  slug: string
  version: SemverInterval[]
  name: string
}

export type ChosenProductDependencyErrors = {
  version: string
  errors: DependencyError[]
}

export interface ChosenProduct extends ProductDetails {
  required: boolean
  isFetching: boolean
  failedToFetch?: boolean | undefined
  isDuplicate: boolean
  dependencyError: ChosenProductDependencyErrors | null
}

export const permittedOutOfSupportProductSlugs: string[] = [
  'p-metrics-forwarder',
  'p-mysql'
]

export type ChosenProductsActions = ClearProductsAction |
  SelectNewProductAction |
  ReceivedProductDetailsAction |
  FailedToFetchProductDetailsAction |
  RemoveProductFromConfigurationAction |
  ChooseProductVersionAction |
  DismissDuplicatedProductWarningAction |
  OpsManagerMinorTargetVersionChangedAction |
  FailedToFetchFoundationDetailsAction |
  RecalculateCompatibilityAction |
  UpdateMinimalTargetVersionsAction
