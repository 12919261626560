export const IMPORTED_DIAGNOSTIC_REPORT = 'IMPORTED_DIAGNOSTIC_REPORT'

export type DiagnosticReportImportedAction = {
  type: typeof IMPORTED_DIAGNOSTIC_REPORT
}

export function importedDiagnosticReport(): DiagnosticReportImportedAction {
  return {
    type: IMPORTED_DIAGNOSTIC_REPORT
  }
}