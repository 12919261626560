export const START_DIAGNOSTIC_REPORT_LOADING = 'START_DIAGNOSTIC_REPORT_LOADING'
export const DONE_DIAGNOSTIC_REPORT_LOADING = 'DONE_DIAGNOSTIC_REPORT_LOADING'

export type DiagnosticReportLoadingAction = {
  type: typeof START_DIAGNOSTIC_REPORT_LOADING | typeof DONE_DIAGNOSTIC_REPORT_LOADING
}

export function startDiagnosticReportLoading(): DiagnosticReportLoadingAction {
  return {
    type: START_DIAGNOSTIC_REPORT_LOADING
  }
}

export function doneDiagnosticReportLoading(): DiagnosticReportLoadingAction {
  return {
    type: DONE_DIAGNOSTIC_REPORT_LOADING
  }
}