import { ProductSlugVersion } from '../repositories/products'

export interface UrlParams {
  chosenProducts: ProductSlugVersion[]
}

function decodeUrlParams(encodedParams: string): UrlParams {
  let params

  try {
    params = JSON.parse(
      atob(
        encodedParams
      )
    )
  } catch (err) {
    params = null
  }

  return params
}

function encodeUrlParams(urlParams: UrlParams): string {
  return btoa(
    JSON.stringify(
      urlParams
    )
  )
}

function newUrlParams(newParams: UrlParams): UrlParams {
  let fullParams: UrlParams = {chosenProducts: []}

  if (newParams.chosenProducts) {
    for (const chosenProduct of newParams.chosenProducts) {
      fullParams.chosenProducts.push({
        slug: chosenProduct.slug,
        current: chosenProduct.current,
        target: chosenProduct.target
      })
    }
  }

  return fullParams
}

export function updateUrlParamsAndCopyToClipboard(params: UrlParams) {
  let newParams = newUrlParams(params)

  window.history.pushState(null, "", encodeUrlParams(newParams))
  navigator.clipboard.writeText(window.location.href)
}

export function loadUrlParams(): UrlParams | null {
  if (window.location.pathname === '/') {
    return null
  }
  let params = window.location.pathname
  if (params[0] === '/') {
    params = params.slice(1)
  }
  return decodeUrlParams(params)
}
