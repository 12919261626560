import * as Constants from '../constants/constants'
import { ProductDetails, ProductRelease } from '../repositories/products'
import { ProductPlanRequest } from '../repositories/upgrade_plan'
import { permittedOutOfSupportProductSlugs } from '../chosen_products/chosen_products_types'
import { Semver } from './semver'


export default class UpgradePlanConfiguration {
  chosenProducts: ProductDetails[]
  listValidProducts: ProductDetails[]
  diagnosticReportImported: boolean

  constructor(chosenProducts: ProductDetails[], diagnosticReportImported: boolean) {
    this.chosenProducts = chosenProducts
    this.diagnosticReportImported = diagnosticReportImported
    this.listValidProducts = this.validProducts(chosenProducts)
  }

  versionForSlug(slug: string, version: string | null): string | null {
    const product = this.listValidProducts.find(p => p.slug === slug)

    if (product && product.releases) {
      const foundRelease: ProductRelease | undefined = product.releases.find(release => release.version === version)
      if (foundRelease) {
        return foundRelease.version || null
      }
    }

    return null
  }

  chosenProductsWithIds(): ProductPlanRequest[] {
    return this.chosenProducts.map((product): ProductPlanRequest => {
      const currentVersion: string | null = this.versionForSlug(product.slug, product.current)
      let targetVersion: string | null = this.versionForSlug(product.slug, product.target)

      return {
        slug: product.slug,
        current: currentVersion,
        target: targetVersion
      }
    })
  }

  validProducts(products: ProductDetails[]): ProductDetails[] {
    const productsWithReleases = this.filterProductsWithNoReleases(products)
    const inSupportProducts = this.filterOutOfSupportProducts(productsWithReleases)
    const releasedProducts = this.filterBetaProducts(inSupportProducts)
    return releasedProducts
  }

  filterProductsWithNoReleases(products: ProductDetails[]): ProductDetails[] {
    return products.filter((product) => {
      const releases: ProductRelease[] = product.releases || []
      return releases.filter((release) => {
        return this.diagnosticReportImported || release.availability !== 'Admins Only'
      }).length
    })
  }

  filterOutOfSupportProducts(products: ProductDetails[]): ProductDetails[] {
    return products.filter((product) => {
      return !Constants.OUT_OF_SUPPORT_RELEASE_LIFECYCLE_LIST.includes(product.lifecycleState) ||
        permittedOutOfSupportProductSlugs.includes(product.slug)
    })
  }

  filterBetaProducts(products: ProductDetails[]): ProductDetails[] {
    return products.filter((product) => {
      return product.lifecycleState !== Constants.BETA_LIFECYCLE_STATE
    })
  }

  apiRequestParams(): ProductPlanRequest[] {
    return this.chosenProductsWithIds()
  }

  isValid(): boolean {
    return this.listValidProducts.length > 0 &&
      this.listValidProducts.every(p =>
        this.currentAndTargetVersionsSelected(p) &&
        !this.isCurrentVersionHigher(p)
      )
  }

  currentAndTargetVersionsSelected(p: ProductDetails): boolean {
    return (p.current !== '' && p.target !== '') ||
      (permittedOutOfSupportProductSlugs.includes(p.slug) && p.current !== '') ||
      Constants.ADDON_SLUGS.includes(p.slug)
  }

  isCurrentVersionHigher(p: ProductDetails): boolean {
    return p.target !== '' && p.target !== null && Semver.fromString(p.current).isHigher(Semver.fromString(p.target))
  }
}
