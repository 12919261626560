import {
  DISMISS_DUPLICATED_PRODUCT_WARNING,
  FAILED_TO_FETCH_FOUNDATION_DETAILS_ACTION,
  OPS_MANAGER_MINOR_TARGET_VERSION_CHANGED_ACTION,
  RECALCULATE_COMPATIBILITY_ACTION,
  RECEIVED_PRODUCT_DETAILS_ACTION,
  SELECTED_NEW_PRODUCT_ACTION,
  UPDATE_MINIMAL_TARGET_VERSIONS_ACTION
} from '../actions/actions'
import { REMOVE_PRODUCT_FROM_CONFIGURATION_ACTION } from '../chosen_products/remove_product_from_configuration_action'
import { CLEAR_PRODUCTS_ACTION } from '../chosen_products/clear_products_action'
import { FAILED_TO_FETCH_PRODUCT_DETAILS_ACTION } from '../chosen_products/failed_to_fetch_product_details_action'
import { CHOOSE_PRODUCT_VERSION_ACTION } from '../chosen_products/choose_product_version_action'
import {
  ChosenProduct,
  ChosenProductDependencyErrors,
  ChosenProductsActions
} from '../chosen_products/chosen_products_types'
import { ReceivedProductDetailsAction } from '../chosen_products/received_product_details_action'
import { Product, ProductDetails, ProductRelease } from '../repositories/products'
import {
  OPSMAN_SLUG,
  SPRING_CLOUD_SERVICE_V1_V2_SLUG,
  SPRING_CLOUD_SERVICE_V3_SLUG,
  TAS_SLUG,
  TKGI_SLUG
} from '../constants/constants'
import { Semver, SemverInterval } from '../services/semver'
import { isOpsMan, OpsManagerVersion, toSemver } from '../repositories/foundation'

function toChosenProduct(
  slug: string,
  name: string,
  required: boolean,
  releases: ProductRelease[] = [],
  current: string = '',
  target: string | null = '',
  lifecycleState: string,
  isFetching: boolean,
  isDuplicate: boolean,
  logoUrl: string,
  pivotalPlatform: boolean,
  dependencyError: ChosenProductDependencyErrors | null = null): ChosenProduct {
  return {
    category: '',
    slug, current, target, name, required, releases, lifecycleState, isFetching, isDuplicate, logoUrl,
    pivotalPlatform, dependencyError
  }
}

function calculateCompatibleReleases(product: ProductDetails, opsManagerVersion: OpsManagerVersion): void {
  product.releases.forEach((release) => {
    if (product.pivotalPlatform) {
      release.isCompatible = compatibleWithOpsmanMinor(product.slug, release, toSemver(opsManagerVersion)) // TODO not same minor
    } else if (release.isCompatible === undefined || release.isCompatible === null) {
      release.isCompatible = true
    }
  })
}

function sanitizeProduct(product: ChosenProduct) {
  product.slug = product.slug.toLowerCase()
  return product
}

function newProduct(product: Partial<ProductDetails> = {}): ChosenProduct {
  let overridenProduct: ChosenProduct = {
    releases: [],
    lifecycleState: '',
    logoUrl: '',
    slug: '',
    current: '',
    target: '',
    name: '',
    required: false,
    isDuplicate: false,
    isFetching: true,
    category: '',
    dependencyError: null,
    pivotalPlatform: false,
    ...product
  }
  return overridenProduct
}

function updateProduct(products: ChosenProduct[], slug: string, product: Partial<ChosenProduct>) {
  return products.map(oldProduct => {
    if (oldProduct.slug !== slug) return oldProduct

    return {...oldProduct, ...product}
  })
}

function removeProduct(products: ChosenProduct[], slug: string): ChosenProduct[] {
  return products.filter((chosenProduct) => chosenProduct.slug !== slug)
}

function trimVersion(version: string): string {
  let semverMatch = version.match(/\d+\.\d+\.\d+/)
  return (semverMatch ? semverMatch[0] : version)
}

function getBestCurrentVersion(current: string, releases: ProductRelease[]): string {
  if (releases.find(release => release.version === current)) {
    return current
  } else if (releases.find(release => release.version === (trimVersion(current)))) {
    return trimVersion(current)
  } else {
    return ''
  }
}

function createSCSv1v2Product(action: ReceivedProductDetailsAction) {
  return newProduct(
    {
      slug: action.productDetails.slug,
      releases: action.productDetails.releases.filter((release) => {
        return release.version.match('^(?:1|2)\\..+') !== null
      }),
      name: action.productDetails.name,
      target: action.productDetails.target
    }
  )
}

function createSCSv3Product(action: ReceivedProductDetailsAction) {
  return newProduct(
    {
      slug: action.requestedSlug,
      releases: action.productDetails.releases.filter((release) => {
        return release.version.match('^(?:1|2)\\..+') === null
      }),
      name: action.productDetails.name,
      target: action.productDetails.target
    }
  )
}

function limitTargetVersionToOpsManagerMinor(chosenProduct: ChosenProduct | ProductDetails, opsManagerVersion: OpsManagerVersion) {
  let targetRelease
  if (chosenProduct.pivotalPlatform) {
    targetRelease = chosenProduct.releases.filter((release) => compatibleWithOpsmanMinor(chosenProduct.slug, release, Semver.fromString(opsManagerVersion.toString())))
  } else {
    targetRelease = chosenProduct.releases
  }

  targetRelease = targetRelease.find(release => release.availability === 'All Users')
  return targetRelease ? targetRelease.version : ''
}

function foundTargetVersion(targetRelease: ProductRelease | null, minimalServiceTileTargetVersion: boolean, chosenProduct: ChosenProduct | ProductDetails) {
  return targetRelease !== null && (!minimalServiceTileTargetVersion || chosenProduct.pivotalPlatform)
}

function isVersionAvailableAndCompatible(release: ProductRelease) {
  return release.availability === 'All Users' && release.isCompatible
}

function calculateTargetVersion(chosenProduct: ChosenProduct | ProductDetails, minimalServiceTileTargetVersion: boolean) {
  if (chosenProduct.target === undefined || chosenProduct.target === null || chosenProduct.target === '') {
    let targetRelease: ProductRelease | null = null
    chosenProduct.releases.forEach((release) => {
      if (foundTargetVersion(targetRelease, minimalServiceTileTargetVersion, chosenProduct)) {
        return
      }

      if (isVersionAvailableAndCompatible(release)) {
        targetRelease = release
      }
    })
    if (targetRelease === null) {
      return ''
    } else {
      return targetRelease!['version']
    }

  }
  return chosenProduct.target
}

function latestAndEarliestCompatible(updateTargetVersion: boolean, prod: ChosenProduct, opsmanVersion: string, tasVersion: string | null, tkgiVersion: string | null): [string | null, string | null] {
  let latestCompatibleVersion: string | null = ''
  let earliestCompatibleVersion: string | null = null

  prod.releases.forEach((release) => {
    release.isCompatible = compatibleWithCurrentOpsmanAndTasOrTKG(release, opsmanVersion, tasVersion, tkgiVersion)
    if (isVersionAvailableAndCompatible(release)) {
      if (prod.target === release.version) {
        updateTargetVersion = false
      }

      if (latestCompatibleVersion === '') {
        latestCompatibleVersion = release.version
        if (prod.target === '') {
          updateTargetVersion = true
        }
      }
      earliestCompatibleVersion = release.version
    } else if (prod.target === release.version) {
      updateTargetVersion = true
    }
  })

  if (!updateTargetVersion && latestCompatibleVersion !== '') {
    return [prod.target, prod.target]
  }

  return [latestCompatibleVersion, earliestCompatibleVersion]
}

function addDependencyErrorToProduct(chosenProducts: ChosenProduct[], platformProductNames: Product[], prod: ChosenProduct, opsmanVersion: string, tasVersion: string, tkgiVersion: string) {
  const release = prod.releases.find((rel) => rel.availability === 'All Users')

  if (release === undefined) {
    return
  }

  let opsManagerDependency = release.dependencies[OPSMAN_SLUG]?.slice().reverse()
  let tasDependency = release.dependencies[TAS_SLUG]?.slice().reverse()
  let tkgiDependency = release.dependencies[TKGI_SLUG]?.slice().reverse()
  prod.dependencyError = {
    errors: [],
    version: release.version
  }

  if (opsManagerDependency !== undefined && !opsManagerDependency.some((dependency) => Semver.fromString(opsmanVersion).satisfies(dependency))) {
    prod.dependencyError.errors.push({
      name: platformProductNames.find((product) => isOpsMan(product.slug))?.name!,
      slug: OPSMAN_SLUG,
      version: SemverInterval.fromStrings(opsManagerDependency.map((dependency) => dependency.version))
    })
  }

  if (tasDependency !== undefined && !tasDependency.some((dependency) => Semver.fromString(tasVersion).satisfies(dependency))) {
    prod.dependencyError.errors.push({
      name: platformProductNames.find((product) => product.slug === TAS_SLUG)?.name!,
      slug: TAS_SLUG,
      version: SemverInterval.fromStrings(tasDependency.map((dependency) => dependency.version))
    })
  }

  if (tkgiDependency !== undefined && !tkgiDependency.some((dependency) => Semver.fromString(tkgiVersion).satisfies(dependency))) {
    prod.dependencyError.errors.push({
      name: platformProductNames.find((product) => product.slug === TKGI_SLUG)?.name!,
      slug: TKGI_SLUG,
      version: SemverInterval.fromStrings(tkgiDependency.map((dependency) => dependency.version))
    })
  }
}

function adjustCompatibilityOfServiceTiles(chosenProducts: ChosenProduct[], platformProductNames: Product[], updateTargetVersion: boolean, minimalServiceTileTargetVersion: boolean) {
  const currentOpsman: ChosenProduct | undefined = chosenProducts.find((product) => isOpsMan(product.slug))
  const currentTas: ChosenProduct | undefined = chosenProducts.find((product) => product.slug === TAS_SLUG)
  const currentTkgi: ChosenProduct | undefined = chosenProducts.find((product) => product.slug === TKGI_SLUG)

  if (currentOpsman === undefined) {
    return chosenProducts
  }

  return chosenProducts.map((product) => {
    const opsmanVersion = currentOpsman.target
    if (isOpsMan(product.slug) || opsmanVersion === null || product.releases.length === 0) {
      return newProduct(product)
    }
    let prod = newProduct(product)

    let [latestCompatibleVersion, earliestCompatibleVersion] = latestAndEarliestCompatible(updateTargetVersion, prod, opsmanVersion, currentTas?.target!, currentTkgi?.target!)
    if (minimalServiceTileTargetVersion) {
      prod.target = earliestCompatibleVersion
    } else {
      prod.target = latestCompatibleVersion
    }

    if (latestCompatibleVersion === '') {
      addDependencyErrorToProduct(chosenProducts, platformProductNames, prod, opsmanVersion, currentTas?.target!, currentTkgi?.target!)
    } else {
      prod.dependencyError = null
    }

    return prod
  })
}

function compatibleWithCurrentOpsmanAndTasOrTKG(release: ProductRelease, currentOpsman: string, currentTas: string | null, currentTkgi: string | null): boolean {
  let opsManagerDependency = release.dependencies[OPSMAN_SLUG]
  let tasDependency = release.dependencies[TAS_SLUG]
  let tkgiDependency = release.dependencies[TKGI_SLUG]
  let result = true
  let dependsOnTAS = false
  let dependsOnTKGi = false
  let isTASPresent = false
  let isTKGiPresent = false
  let isOpsmanPresent = true
  if (opsManagerDependency !== undefined) {
    isOpsmanPresent = opsManagerDependency.some((dependency) => Semver.fromString(currentOpsman).satisfies(dependency))
    result = result && isOpsmanPresent
  }

  if (tasDependency !== undefined) {
    dependsOnTAS = true
    if (currentTas === null) {
      result = false
    } else {
      isTASPresent = tasDependency.some((dependency) => Semver.fromString(currentTas).satisfies(dependency))
      result = result && isTASPresent
    }
  }

  if (tkgiDependency !== undefined) {
    dependsOnTKGi = true
    if (currentTkgi === null) {
      result = false
    } else {
      isTKGiPresent = tkgiDependency.some((dependency) => Semver.fromString(currentTkgi).satisfies(dependency))
      result = result && isTKGiPresent
    }
  }

  if (dependsOnTKGi && dependsOnTAS) {
    result = isOpsmanPresent && (isTKGiPresent || isTASPresent)
  }

  return result
}

function updateTargetVersionsToMinimumCompatible(chosenProducts: ChosenProduct[]) {
  return chosenProducts.map((product) => {
    let prod = newProduct(product)
    if (prod.pivotalPlatform) {
      return prod
    }

    let minimumCompatibleVersion = prod.target
    prod.releases.forEach((release) => {
      if (isVersionAvailableAndCompatible(release)) {
        minimumCompatibleVersion = release.version
      }
    })

    if (minimumCompatibleVersion !== null) {
      if (Semver.fromString(prod.current).isHigher(Semver.fromString(minimumCompatibleVersion))) {
        prod.target = prod.current
      } else {
        prod.target = minimumCompatibleVersion
      }
    } else {
      prod.target = minimumCompatibleVersion
    }

    return prod
  })
}

export default function chosenProductsReducer(chosenProducts: ChosenProduct[] = [], action: ChosenProductsActions) {
  switch (action.type) {
  case DISMISS_DUPLICATED_PRODUCT_WARNING: {
    return chosenProducts.map(product => {
      return {...product, isDuplicate: false}
    })
  }

  case CLEAR_PRODUCTS_ACTION: {
    return []
  }

  case SELECTED_NEW_PRODUCT_ACTION: {
    const existingProduct = chosenProducts.find((chosenProduct) => chosenProduct.slug === action.value.slug)
    if (existingProduct) {
      return updateProduct(chosenProducts, existingProduct.slug, {isDuplicate: true})
    }

    let selectedProduct = [sanitizeProduct(newProduct(action.value))]

    return chosenProducts.concat(selectedProduct)
  }

  case RECEIVED_PRODUCT_DETAILS_ACTION: {
    const products: ChosenProduct[] = []

    chosenProducts.forEach((chosenProduct) => {
      if (chosenProduct.slug !== action.requestedSlug) {
        products.push(chosenProduct)
        return
      }

      let product: ProductDetails = action.productDetails
      if (action.requestedSlug === SPRING_CLOUD_SERVICE_V1_V2_SLUG) {
        product = createSCSv1v2Product(action)
      } else if (action.requestedSlug === SPRING_CLOUD_SERVICE_V3_SLUG) {
        product = createSCSv3Product(action)
      }

      const currentVersion = (!chosenProduct.current ? '' : getBestCurrentVersion(chosenProduct.current, product.releases))
      calculateCompatibleReleases(product, action.opsManagerMinorVersion)
      const targetVersion = calculateTargetVersion(product, action.minimalServiceTileTargetVersion)

      products.push(toChosenProduct(
        product.slug,
        product.name,
        isOpsMan(product.slug),
        product.releases,
        currentVersion,
        targetVersion,
        action.productDetails.lifecycleState,
        false,
        chosenProduct.isDuplicate,
        action.productDetails.logoUrl || '',
        product.pivotalPlatform,
        null
      ))
    })

    return products
  }

  case FAILED_TO_FETCH_PRODUCT_DETAILS_ACTION:
    return updateProduct(chosenProducts, action.slug, {failedToFetch: true, isFetching: false})

  case REMOVE_PRODUCT_FROM_CONFIGURATION_ACTION:
    return removeProduct(chosenProducts, action.product)

  case CHOOSE_PRODUCT_VERSION_ACTION:
    let update: Partial<ChosenProduct> = {}
    if (action.currentVersion !== null) {
      update.current = action.currentVersion || undefined
    }
    if (action.targetVersion !== null) {
      update.target = action.targetVersion || undefined
    }
    return updateProduct(chosenProducts, action.product, update)

  case OPS_MANAGER_MINOR_TARGET_VERSION_CHANGED_ACTION:
    const products: ChosenProduct[] = []

    chosenProducts.forEach((chosenProduct) => {
      if (!chosenProduct.pivotalPlatform) {
        products.push(chosenProduct)
        return
      }

      const targetVersion = limitTargetVersionToOpsManagerMinor(chosenProduct, action.opsManagerMinorVersion)
      calculateCompatibleReleases(chosenProduct, action.opsManagerMinorVersion)

      products.push({...chosenProduct, ...{target: targetVersion}})
    })

    return products

  case FAILED_TO_FETCH_FOUNDATION_DETAILS_ACTION:
    let foundationProducts: ChosenProduct[] = chosenProducts.slice(0)
    action.slugs.forEach((slug: string) => {
      foundationProducts = updateProduct(foundationProducts, slug, {failedToFetch: true, isFetching: false})
    })
    return foundationProducts

  case RECALCULATE_COMPATIBILITY_ACTION:
    return adjustCompatibilityOfServiceTiles(chosenProducts, action.platformProductNames, action.updateTargetVersion, action.minimalServiceTileTargetVersion)

  case UPDATE_MINIMAL_TARGET_VERSIONS_ACTION:
    return updateTargetVersionsToMinimumCompatible(chosenProducts)
  default:
    return chosenProducts
  }
}

function compatibleWithOpsmanMinor(slug: string, release: ProductRelease, opsmanMinor: Semver) {
  let opsManagerDependency = release.dependencies[OPSMAN_SLUG]
  if (opsManagerDependency !== undefined) {
    return opsManagerDependency.some((dependency) => opsmanMinor.satisfiesMinor(dependency))
  } else if (slug === OPSMAN_SLUG) {
    return Semver.fromString(release.version).sameMinor(opsmanMinor)
  }
  return true
}
