import store from '../stores/store'
import { OPS_MANAGER_MINOR_TARGET_VERSION_CHANGED_ACTION, UPDATE_OPS_MANAGER_TARGET_VERSION_ACTION } from './actions'
import { OpsManagerVersion } from '../repositories/foundation'

export type OpsManagerMinorTargetVersionChangedAction = {
  type: typeof OPS_MANAGER_MINOR_TARGET_VERSION_CHANGED_ACTION
  opsManagerMinorVersion: OpsManagerVersion
}
export type UpdateOpsManagerMinorTargetVersionAction = {
  type: typeof UPDATE_OPS_MANAGER_TARGET_VERSION_ACTION
  version: OpsManagerVersion
}

export function opsManagerMinorTargetVersionChanged(): OpsManagerMinorTargetVersionChangedAction {
  return {
    type: OPS_MANAGER_MINOR_TARGET_VERSION_CHANGED_ACTION,
    opsManagerMinorVersion: store.getState().opsManagerMinorVersion
  }
}

export function updateOpsManagerMinorTargetVersion(minorVersion: OpsManagerVersion): UpdateOpsManagerMinorTargetVersionAction {
  return {
    type: UPDATE_OPS_MANAGER_TARGET_VERSION_ACTION,
    version: minorVersion
  }
}