import { DISMISS_DUPLICATED_PRODUCT_WARNING } from './actions'

export type DismissDuplicatedProductWarningAction = {
  type: typeof DISMISS_DUPLICATED_PRODUCT_WARNING,
}

export function dismissDuplicatedProductWarning(): DismissDuplicatedProductWarningAction {
  return {
    type: DISMISS_DUPLICATED_PRODUCT_WARNING,
  }
}
