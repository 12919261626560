import {
  DiagnosticReportLoadingAction, DONE_DIAGNOSTIC_REPORT_LOADING, START_DIAGNOSTIC_REPORT_LOADING
} from '../actions/diagnostic_report_loading'

export default function diangosticReportLoading(diagnosticReportLoading: boolean = false, action: DiagnosticReportLoadingAction) {
  switch (action.type) {
  case START_DIAGNOSTIC_REPORT_LOADING:
    return true
  case DONE_DIAGNOSTIC_REPORT_LOADING:
    return false
  default:
    return diagnosticReportLoading
  }
}