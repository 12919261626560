import productVersionLookup from '../services/product_version_lookup'
import { Dispatch } from 'redux'
import { ProductDetails } from '../repositories/products'
import { FoundationRepository } from '../repositories/foundation'
import { DiagnosticReportProductsHandler } from './add_products_from_diagnostic_report'

export const PARSE_PRODUCT_CONFIGURATION = 'PARSE_PRODUCT_CONFIGURATION'

export type DiagnosticVersion = {
  version: string,
  name: string
}

function getSlug(product: DiagnosticVersion) {
  const defaultSlug = product.name.toLowerCase()
  switch (defaultSlug) {
  case 'p-bosh':
    return 'ops-manager'
  case 'cf':
    return 'elastic-runtime'
  default:
    return defaultSlug
  }
}

function prepareJson(pastedOpsmanDiagnostic: string) {
  return pastedOpsmanDiagnostic.replace(/“/g, '"')
}

function parseHealthwatch(products: DiagnosticVersion[]) {
  const hw1 = products.find((p) => p.name === 'p-healthwatch')
  const hw2 = products.find((p) => p.name === 'p-healthwatch2')

  if ((hw1 && hw2) || hw2) {
    hw2.name = 'p-healthwatch'
    return hw2
  }

  return hw1
}

function parseProductConfiguration(foundationRepository: FoundationRepository, pastedOpsmanDiagnostic: string, handleDiagnosticReport: DiagnosticReportProductsHandler): (dispatch: Dispatch) => void {
  return (dispatch: Dispatch) => {
    let parsedJSON

    try {
      parsedJSON = JSON.parse(prepareJson(pastedOpsmanDiagnostic))
    } catch (e) {
      dispatch(productConfigurationParsed(false))
      return
    }

    if (parsedJSON.added_products === undefined) {
      dispatch(productConfigurationParsed(false))
      return
    }

    const filteredProducts = parsedJSON
      .added_products.deployed
      .filter((product: DiagnosticVersion) => !['p-healthwatch', 'p-healthwatch2'].includes(product.name))

    const hw = parseHealthwatch(parsedJSON.added_products.deployed)
    if (hw) filteredProducts.push(hw)

    const products: ProductDetails[] = filteredProducts
      .map((product: DiagnosticVersion): Partial<ProductDetails> => {
        return {
          slug: getSlug(product),
          name: product.name,
          current: productVersionLookup(product.name, product.version)
        }
      })

    handleDiagnosticReport(foundationRepository, products)

    dispatch(productConfigurationParsed(true))
  }
}

export default parseProductConfiguration

export interface ProductConfigurationParsed {
  isValid: boolean
}

export type ProductConfigurationAction = {
  type: typeof PARSE_PRODUCT_CONFIGURATION
  isValid: boolean
}

export type ProductConfigurationTypes = ProductConfigurationAction

function productConfigurationParsed(isValid: boolean): ProductConfigurationAction {
  return {
    type: PARSE_PRODUCT_CONFIGURATION,
    isValid: isValid
  }
}