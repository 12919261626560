const PRODUCT_CONFIGURATION_JSON_PASTED = 'PRODUCT_CONFIGURATION_JSON_PASTED'

export type ProductConfigurationPastedAction = {
  type: typeof PRODUCT_CONFIGURATION_JSON_PASTED,
  value: string
}

export default function productConfigurationPasted(pastedOpsmanDiagnostic: string): ProductConfigurationPastedAction {
  return {
    type: PRODUCT_CONFIGURATION_JSON_PASTED,
    value: pastedOpsmanDiagnostic
  }
}