import { SIGNAL_PIVNET_DOWN } from './server_status_types'

const signalPivnetDown = (errorMessage: string) => {
  return {
    type: SIGNAL_PIVNET_DOWN,
    message: errorMessage
  }
}

export default signalPivnetDown
