import React from 'react'

import { Image } from 'pivotal-ui/react/images'
import { Icon } from 'pivotal-ui/react/iconography'
import { FlexCol, Grid } from 'pivotal-ui/react/flex-grids'

import ReleaseDropdown from './release_dropdown'
import RemoveProductButton from './remove_product_button'
import ProductDetail from './product_detail'
import { ChosenProduct, ChosenProductErrors } from './chosen_products_types'
import { ProductRelease } from '../repositories/products'
import { Semver } from '../services/semver'
import { METRICS_FORWARDER_SLUG } from '../constants/constants'

const waitingRow = ({slug}: Partial<ChosenProduct>) => {
  return (
    <div className={'plxl mvl border-top-0 aligner height-32'} key={slug}>Waiting for releases
      for {slug}</div>
  )
}

type ProductRowProps = {
  chosenProduct: ChosenProduct,
  releases: ProductRelease[],
  errors: ChosenProductErrors
}

function productInfoBanner(slug: string) {
  let message = ''
  if (slug === 'p-mysql') {
    message = 'This product is no longer supported. We will add the recommended migration to MySQL for VMware Tanzu in your upgrade plan.'
  } else if (slug === METRICS_FORWARDER_SLUG) {
    message = 'This product is no longer supported. We will insert the recommended instructions for transitioning to Metric Registrar, the replacement for custom metrics functionality.'
  }

  return <div className='banner'>
    <div className='banner-text'>
      <Grid className={'plxl aligner height-auto'}>
        <FlexCol className='icon-col' col={1} alignment='middle' contentAlignment='middle'>
          <Icon src='info'/>
        </FlexCol>
        <FlexCol className='message plxxl'>
          <b>Info:</b> {message}
        </FlexCol>
      </Grid>
    </div>
  </div>
}

function downgradeVersionBanner() {
  return <div className='banner'>
    <div className='banner-text'>
      <Grid className={'plxl aligner height-auto'}>
        <FlexCol className='icon-col' col={1} alignment='middle' contentAlignment='middle'>
          <Icon src='report'/>
        </FlexCol>
        <FlexCol className='message plxxl'>
          <b>Warning:</b> Product downgrades are not supported.
          Please ensure target version is equal to or higher than the current version.
        </FlexCol>
      </Grid>
    </div>
  </div>
}

function dependencyError(chosenProduct: ChosenProduct) {
  let result = chosenProduct.dependencyError?.errors.map((error) => {
    return `${error.name} ${error.version.join(', ')}`
  }).join(' and ')

  let names = chosenProduct.dependencyError?.errors.map((error) => {
    return error.name
  }).join(' and ')

  let message = ` The latest version of ${chosenProduct.name} (${chosenProduct.dependencyError?.version}) requires ${result}.
  To generate a plan you must adjust the target version of ${names} or remove this tile from your foundation.`
  return <div className='banner'>
    <div className='banner-text'>
      <Grid className={'plxl aligner height-auto'}>
        <FlexCol className='icon-col' col={1} alignment='middle' contentAlignment='middle'>
          <Icon src='info'/>
        </FlexCol>
        <FlexCol className='message plxxl'>
          <b>Warning:</b> {message}
        </FlexCol>
      </Grid>
    </div>
  </div>
}

const ProductRow = ({chosenProduct, releases, errors}: ProductRowProps) => {
  let rowName = chosenProduct.slug + '-product-row'

  if (chosenProduct.isFetching) {
    return waitingRow(chosenProduct)
  }

  let className = 'border border-top-0'
  let banner = (<></>)
  let targetClassnames: string[] = ['release-dropdown']
  if (chosenProduct.slug === 'p-mysql' || chosenProduct.slug === METRICS_FORWARDER_SLUG) {
    className += ' product-row-with-info'
    banner = (productInfoBanner(chosenProduct.slug))
  } else if (chosenProduct.target !== '' && chosenProduct.target != null && Semver.fromString(chosenProduct.current).isHigher(Semver.fromString(chosenProduct.target))) {
    className += ' product-row-with-error'
    banner = downgradeVersionBanner()
  } else if(chosenProduct.dependencyError !== null) {
    targetClassnames.push('warning')
    className += ' product-row-with-warning'
    banner = dependencyError(chosenProduct)
  }

  let currentEOGS = 'N/A'
  const currentRelease = releases.find((release: ProductRelease) => release.version === chosenProduct.current)
  if (currentRelease !== undefined && currentRelease.endOfSupportDate !== null) {
    currentEOGS = currentRelease.endOfSupportDate.replace('/', '-')
  }

  let targetEOGS = 'N/A'
  const targetRelease = releases.find((release: ProductRelease) => release.version === chosenProduct.target)
  if (targetRelease !== undefined && targetRelease.endOfSupportDate !== null) {
    targetEOGS = targetRelease.endOfSupportDate.replace('/', '-')
  }

  return (
    <div className={className}>
      {banner}
      <Grid className={'aligner pvxl height-auto no-margin'} name={rowName}>
        <FlexCol col={9} contentAlignment={'top'} className={'plxl'} fixed>
          <Grid className={'aligner height-auto'}>
            <FlexCol col={4} fixed>
              <Image
                className={'height-32 align-self-center'}
                src={chosenProduct.logoUrl}
                alt={''}
                responsive={true}
              />
            </FlexCol>
            <FlexCol col={20} fixed className={''}>
              <ProductDetail product={chosenProduct}/>
            </FlexCol>
          </Grid>
        </FlexCol>
        <FlexCol fixed className={'col-3-5 txt-c'}>
          <ReleaseDropdown
            classNames={[]}
            type={'current'} product={chosenProduct} releases={releases} version={chosenProduct.current}
            error={errors.current} releaseDropdownVersionFilter={''}/>
        </FlexCol>
        <FlexCol fixed className={'col-3-5 txt-c'} id={'current-eogs-date'}>
          {currentEOGS}
        </FlexCol>
        <FlexCol fixed className={'col-3-5 txt-c'}>
          <ReleaseDropdown
            classNames={targetClassnames}
            type={'target'} product={chosenProduct} releases={releases}
            version={chosenProduct.target}
            error={errors.target} releaseDropdownVersionFilter={''}/>
        </FlexCol>
        <FlexCol fixed className={'col-3-5 txt-c'} id={'target-eogs-date'}>
          {targetEOGS}
        </FlexCol>

        <FlexCol col={1} fixed className={'txt-c'}>
          {
            chosenProduct.required
              ? null
              : (
                <RemoveProductButton product={chosenProduct}/>
              )
          }
        </FlexCol>
      </Grid>
    </div>
  )
}

export default ProductRow
