import { SELECT_MINIMAL_SERVICE_TILE_TARGET_VERSIONS_ACTION, UPDATE_MINIMAL_TARGET_VERSIONS_ACTION } from './actions'

export type SelectMinimalServiceTileTargetVersionsAction = {
  type: typeof SELECT_MINIMAL_SERVICE_TILE_TARGET_VERSIONS_ACTION
  minimalServiceTileTargetsSelected: boolean
}

export type UpdateMinimalTargetVersionsAction = {
  type: typeof UPDATE_MINIMAL_TARGET_VERSIONS_ACTION
}

export function selectMinimalServiceTileTargetVersions(minimalServiceTileTargetsSelected: boolean): SelectMinimalServiceTileTargetVersionsAction {
  return {
    type: SELECT_MINIMAL_SERVICE_TILE_TARGET_VERSIONS_ACTION,
    minimalServiceTileTargetsSelected: minimalServiceTileTargetsSelected
  }
}

export function updateMinimalTargetVersions(): UpdateMinimalTargetVersionsAction {
  return {
    type: UPDATE_MINIMAL_TARGET_VERSIONS_ACTION
  }
}
