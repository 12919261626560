import React from 'react'

type NotApplicableProps = {
  slug: string
  type: string
  text: string
}

// divs do not have `name` attribute so we had to convert the name into ID if we feel like
// this is a problem we should come back here and maybe revert it and change some types
export default function NotApplicable({slug, type, text}: NotApplicableProps) {
  return (
    <div id={`${slug}-release-${type}-select`} className='dropdown dropdown-closed release-dropdown aligner-item'>
      <button type='button' className='dropdown-toggle bg-white' id='not-applicable' disabled><i>{text}</i>
      </button>
    </div>)
}

NotApplicable.defaultProps = {
  text: 'Not Applicable'
}