import React from 'react'
import { connect } from 'react-redux'
import { PrimaryButton } from 'pivotal-ui/react/buttons'
import { ErrorAlert } from 'pivotal-ui/react/alerts'
import { LOADING_STATES } from '../reducers/upgrade_plan_loading_state'

import productConfigurationPasted, { ProductConfigurationPastedAction } from '../actions/product_configuration_pasted'
import parseProductConfiguration, { ProductConfigurationParsed } from '../actions/parse_product_configuration'
import { clearProducts, ClearProductsAction } from '../chosen_products/clear_products_action'
import clearProductErrors from '../error_info/clear_error_info_action'
import {
  changeUpgradePlanLoadingState, ChangeUpgradePlanLoadingStateAction
} from '../actions/change_upgrade_plan_loading_state'
import { ErrorInfoActionTypes, ErrorInfoState, PlanErrorTypes } from '../error_info/error_info_types'
import AnalyticsWrapper from '../services/analytics_wrapper'
import { UpgradePortalStore } from '../stores/store'
import {
  addProductsFromDiagnosticReport, DiagnosticReportProductsHandler
} from '../actions/add_products_from_diagnostic_report'
import { FoundationRepository } from '../repositories/foundation'
import { ProductDetails } from '../repositories/products'

export type ProductConfigurationPasteMenuProps = {
  pastedOpsmanDiagnostic: string,
  parsedProductConfig: ProductConfigurationParsed,
  errorInfo: ErrorInfoState,

  productConfigurationPasted: (pastedOpsmanDiagnostic: string) => ProductConfigurationPastedAction,
  clearProducts: () => ClearProductsAction,
  clearProductErrors: () => ErrorInfoActionTypes,
  parseProductConfiguration: (foundationRepository: FoundationRepository, pastedOpsmanDiagnostic: string, handleDiagnosticReport: DiagnosticReportProductsHandler) => void
  foundationRepository: FoundationRepository,
  analyticsWrapper: AnalyticsWrapper,
  changeUpgradePlanLoadingState: (state: LOADING_STATES) => ChangeUpgradePlanLoadingStateAction,
  addProductsFromDiagnosticReport: (foundationRepository: FoundationRepository, products: ProductDetails[]) => any
}

const ProductConfigurationPasteMenu = (
  {
    pastedOpsmanDiagnostic,
    productConfigurationPasted,
    clearProducts,
    clearProductErrors,
    parseProductConfiguration,
    foundationRepository,
    analyticsWrapper,
    parsedProductConfig,
    errorInfo,
    changeUpgradePlanLoadingState,
    addProductsFromDiagnosticReport
  }: ProductConfigurationPasteMenuProps) =>
  <div>
    {!parsedProductConfig.isValid &&
    <ErrorAlert>Please ensure your diagnostic report is valid JSON and try again.</ErrorAlert>}
    <textarea
      onChange={(event) => {
        productConfigurationPasted(event.target.value)
      }}
      placeholder={'Paste Diagnostic Report'}
      aria-label={'Paste Diagnostic Report'}
      className={'resize-vertical'}
      id={'diagnostic-report'}
    />
    <div className={'txt-r mtl'}>
      <PrimaryButton
        id={'add-pasted-config-json'}
        disabled={!pastedOpsmanDiagnostic}
        alt
        onClick={() => {
          clearProducts()
          if (errorInfo.type !== PlanErrorTypes.NO_ERROR) changeUpgradePlanLoadingState(LOADING_STATES.DEFAULT)
          clearProductErrors()
          parseProductConfiguration(foundationRepository, pastedOpsmanDiagnostic, addProductsFromDiagnosticReport)
          analyticsWrapper.fireEvent('Button', 'Click', 'Add Products From Report')
        }}
      >
        Add Products From Report
      </PrimaryButton>
    </div>
  </div>

const mapStateToProps = (state: UpgradePortalStore) => {
  return state
}

const mapDispatchToProps = {
  productConfigurationPasted,
  clearProducts,
  clearProductErrors,
  parseProductConfiguration,
  changeUpgradePlanLoadingState,
  addProductsFromDiagnosticReport
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductConfigurationPasteMenu)

export { ProductConfigurationPasteMenu }
