import { ProductDetails } from '../repositories/products'
import store from '../stores/store'
import { RECEIVED_PRODUCT_DETAILS_ACTION } from '../actions/actions'
import { OpsManagerVersion } from '../repositories/foundation'

export type ReceivedProductDetailsAction = {
  type: typeof RECEIVED_PRODUCT_DETAILS_ACTION,
  requestedSlug: string,
  productDetails: ProductDetails,
  opsManagerMinorVersion: OpsManagerVersion,
  minimalServiceTileTargetVersion: boolean
}

const receivedProductDetails = (requestedSlug: string, productDetails: ProductDetails): ReceivedProductDetailsAction => {
  return {
    type: RECEIVED_PRODUCT_DETAILS_ACTION,
    requestedSlug,
    productDetails,
    minimalServiceTileTargetVersion: store.getState().selectMinimalServiceTileTargetVersions,
    opsManagerMinorVersion: store.getState().opsManagerMinorVersion
  }
}

export default receivedProductDetails
