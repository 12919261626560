import { ProductRepository } from '../repositories/products'
import { Dispatch } from 'redux'
import { loadUrlParams, UrlParams } from '../services/url_params'
import receivedProductDetails from '../chosen_products/received_product_details_action'
import { SELECTED_NEW_PRODUCT_ACTION } from './actions'
import { recalculateCompatibility } from './recalculate_compatibility'
import { isOpsMan, toOpsManagerVersion } from '../repositories/foundation'
import { updateOpsManagerMinorTargetVersion } from './select_ops_manager_minor_target_version'
import clearProductErrors from '../error_info/clear_error_info_action'
import { clearProducts } from '../chosen_products/clear_products_action'
import { chooseProductVersions } from '../chosen_products/choose_product_version_action'

export type NoURLParams = {}
export type OperationsManagerNotFound = {}

export function retrieveProductsFromUrlAsync(productRepository: ProductRepository, loadProductsFromUrl: () => UrlParams | null = loadUrlParams): (dispatch: Dispatch) => (Promise<NoURLParams> | Promise<OperationsManagerNotFound>) {
  return (dispatch: Dispatch): Promise<any> => {
    const urlParams = loadProductsFromUrl()
    if (urlParams === null) {
      return Promise.reject<NoURLParams>()
    }

    let opsman = urlParams.chosenProducts.find((productVersion) => isOpsMan(productVersion.slug))
    if (!opsman) {
      return Promise.reject<OperationsManagerNotFound>()
    }

    dispatch(clearProductErrors())
    dispatch(clearProducts())
    dispatch(updateOpsManagerMinorTargetVersion(toOpsManagerVersion(opsman.target || '')))

    return Promise.all(
      urlParams.chosenProducts.map((productVersion) => {
        dispatch({
          type: SELECTED_NEW_PRODUCT_ACTION,
          value: {
            slug: productVersion.slug,
            name: '',
            current: productVersion.current
          }
        })

        return productRepository.getProductDetails(dispatch, productVersion.slug)
          .then(productDetails => {
            dispatch(receivedProductDetails(productVersion.slug, productDetails))
            dispatch(chooseProductVersions(productVersion.slug, productVersion.current, productVersion.target))
          })
      })
    ).then(() => {
      dispatch(recalculateCompatibility(false))
    })
  }
}
