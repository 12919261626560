import { RetrieveProductAction } from '../actions/retrieve_products'
import { Product } from '../repositories/products'
import { RETRIEVE_PRODUCTS } from '../actions/actions'

export default function productsReducer(products: Product[] = [], action: RetrieveProductAction) {
  switch (action.type) {
  case RETRIEVE_PRODUCTS:
    return products = action.products
  default:
    return products
  }
}