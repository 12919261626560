import {
  CLEAR_PRODUCT_ERROR,
  ErrorInfoActionTypes
} from "./error_info_types"

const clearProductErrors = () : ErrorInfoActionTypes => {
  return {
    type: CLEAR_PRODUCT_ERROR,
  }
}

export default clearProductErrors
