import {CLEAR_PRODUCT_ERROR, ErrorInfoActionTypes, ErrorInfoState, LOAD_ERROR_INFO, PlanErrorTypes} from "./error_info_types"

const initialState: ErrorInfoState = {
  type: PlanErrorTypes.NO_ERROR
}

export default function errorInfo(state: ErrorInfoState = initialState, action: ErrorInfoActionTypes): ErrorInfoState {
  switch(action.type) {
  case LOAD_ERROR_INFO:
    return action.value
  case CLEAR_PRODUCT_ERROR:
    return {
      type: PlanErrorTypes.NO_ERROR
    }
  default:
    return state
  }
}
