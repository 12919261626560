import { WarningAlert } from 'pivotal-ui/react/alerts'
import { ListItem, UnorderedList } from 'pivotal-ui/react/lists'
import React from 'react'
import { PlanAlertLevels } from '../../chosen_products/chosen_products_types'
import { Alert } from '../../repositories/upgrade_plan'

export const WarningsSection = ({alerts}: { alerts: Alert[] }) => {
  let filteredWarnings = alerts.filter((alert: Alert) => {
    return alert.level === PlanAlertLevels.WARNING
  })

  if (filteredWarnings.length === 0) return null

  return (
    <div className='mbxl type-sm'>
      <WarningAlert withIcon>
        <UnorderedList>
          The following products have alerts:
          {filteredWarnings.map((alert, index) => {
            return (<ListItem key={index} dangerouslySetInnerHTML={{__html: alert.message}}></ListItem>)
          })}
        </UnorderedList>
      </WarningAlert>
    </div>
  )
}
