import React from 'react'
import { connect } from 'react-redux'
import AnalyticsWrapper from '../services/analytics_wrapper'
import { Plan, PlanRepository } from '../repositories/upgrade_plan'
import { disablePdfButton, enablePdfButton } from '../actions/pdf_download_button'
import { UpgradePortalStore } from '../stores/store'

type ButtonDownloadPdfProps = {
  analyticsWrapper: AnalyticsWrapper
  upgradePlanRepository: PlanRepository
  upgradePlan: Plan
  downloadPdf: (upgradePlanRepository: PlanRepository, upgradePlan: Plan) => Promise<null>
  enablePdfButton: () => void
  disablePdfButton: () => void
}

export const ButtonDownloadPdf = ({analyticsWrapper, upgradePlanRepository, upgradePlan, downloadPdf, enablePdfButton, disablePdfButton}: ButtonDownloadPdfProps) => {
  return (
    <div
      id={'download-pdf-btn'}
      onClick={() => {
        disablePdfButton()
        analyticsWrapper.fireEvent('Button', 'Click', 'Download PDF')
        downloadPdf(upgradePlanRepository, upgradePlan).catch(() => {
          analyticsWrapper.fireEvent('Error', 'Click', 'Download PDF')
        }).then(() => {
          enablePdfButton()
        })
      }}
    >
      PDF
    </div>
  )
}

const mapDispatchToProps = {
  enablePdfButton,
  disablePdfButton
}

const mapStateToProps = ({upgradePlan}: UpgradePortalStore) => {
  return {
    upgradePlan
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonDownloadPdf)
