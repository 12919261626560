import { ProductDependency } from '../repositories/products'

enum SemverType {
  VERSION,
  WILDCARD,
  PATH_AND_ABOVE
}

export class Semver {
  major: string
  minor: string
  patch: string
  subpatch: string | null
  build: string | null
  semverType: SemverType

  constructor(major: string, minor: string, patch: string, subpatch: string | null = null, build: string | null = null, semverType: SemverType = SemverType.VERSION) {
    this.major = major
    this.minor = minor
    this.patch = patch
    this.subpatch = subpatch
    this.build = build
    this.semverType = semverType
  }

  static fromString(version: string): Semver {
    if (version === undefined) {
      return new Semver('0', '0', '0')
    }

    let build : string | null = null
    const buildParts = version.split('+')
    if (buildParts.length > 1 && buildParts[1] !== '') {
      version = buildParts[0]
      build = buildParts[1]
    }

    const versionParts = version.split('.')

    let semverType = SemverType.VERSION
    let major = versionParts[0]
    if (major.startsWith('~>')) {
      semverType = SemverType.PATH_AND_ABOVE
      major = major.substr(2)
    }
    let minor = versionParts[1]
    let patch = versionParts[2]
    let subpatch = versionParts[3]
    if (patch === '*' && semverType === SemverType.PATH_AND_ABOVE) {
      return new Semver('0', '0', '0')
    } else if (patch === '*' && subpatch != null) {
      return new Semver('0', '0', '0')
    } else if (patch === '*') {
      semverType = SemverType.WILDCARD
    }

    return new Semver(major, minor, patch, subpatch, build, semverType)
  }

  sameMinor(other: Semver): boolean {
    return this.major === other.major && this.minor === other.minor
  }

  isHigher(other: Semver): boolean {
    let higherMajor = Number(this.major) > Number(other.major)
    let sameMajorHigherMinor = (
      Number(this.major) === Number(other.major) &&
        Number(this.minor) > Number(other.minor)
    )
    let sameMajorMinorHigherPatch = (
      Number(this.major) === Number(other.major) &&
        Number(this.minor) === Number(other.minor) &&
        Number(this.patch) > Number(other.patch)
    )
    let sameSemver = (
      Number(this.major) === Number(other.major) &&
        Number(this.minor) === Number(other.minor) &&
        Number(this.patch) === Number(other.patch)
    )

    let thisSubpatch = this.subpatch ? Number(this.subpatch) : -1
    let otherSubpatch = other.subpatch ? Number(other.subpatch) : -1
    let sameSemverHigherSubpatch = sameSemver && (thisSubpatch > otherSubpatch)


    return higherMajor || sameMajorHigherMinor || sameMajorMinorHigherPatch || sameSemverHigherSubpatch
  }

  compare(other: Semver): number {
    let sameMajor = Number(this.major) === Number(other.major)
    let sameMinor = Number(this.minor) === Number(other.minor)
    let samePatch = Number(this.patch) === Number(other.patch)
    let thisSubpatch = this.subpatch ? Number(this.subpatch) : -1
    let otherSubpatch = other.subpatch ? Number(other.subpatch) : -1
    let sameSubpatch = thisSubpatch === otherSubpatch

    if (this.isHigher(other)) {
      return 1
    } else if (sameMajor && sameMinor && samePatch && sameSubpatch) {
      return 0
    }
    return -1
  }

  satisfies(dependencySpecifier: ProductDependency): boolean {
    let dependencySemver = Semver.fromString(dependencySpecifier.version)

    if (dependencySemver.major !== this.major) {
      return false
    }
    if (dependencySemver.minor !== this.minor) {
      return false
    }

    switch (dependencySpecifier.versionType) {
    case 'wildcard':
      return true
    case 'single_version':
      return this.patch === dependencySemver.patch
    case 'patch_and_above':
      return Number(this.patch) >= Number(dependencySemver.patch)
    default:
      return false
    }
  }

  satisfiesMinor(dependencySpecifier: ProductDependency): boolean {
    let dependencySemver = Semver.fromString(dependencySpecifier.version)

    if (dependencySemver.major !== this.major) {
      return false
    }
    if (dependencySemver.minor !== this.minor) {
      return false
    }

    return true
  }

  toString(fullNotation: boolean = false): string {
    let initialModifier = ''
    if (fullNotation && this.semverType === SemverType.PATH_AND_ABOVE) {
      initialModifier = '>='
    }
    let semverString = `${initialModifier}${this.major}.${this.minor}.${this.patch}`

    if (this.subpatch) {
      semverString += `.${this.subpatch}`
    }

    if (this.build) {
      semverString += `+${this.build}`
    }
    return semverString
  }
}

export class SemverInterval {
  startInterval: Semver
  endInterval: Semver | null

  constructor(startInterval: Semver, endInterval?: Semver) {
    this.startInterval = startInterval
    this.endInterval = endInterval === undefined ? null : endInterval
  }

  static fromString(version: string): SemverInterval {
    return new SemverInterval(Semver.fromString(version))
  }

  //Note: assumption that versions are sorted from smallest to biggest.
  static fromStrings(versions: string[]): SemverInterval[] {
    let intervals: SemverInterval[] = []
    versions.forEach((version) => {
      let versionToAdd = Semver.fromString(version)
      if(intervals.length === 0) {
        intervals.push(new SemverInterval(versionToAdd))
        return
      }
      if(!intervals[intervals.length - 1].addToInterval(versionToAdd)) {
        intervals.push(new SemverInterval(versionToAdd))
      }
    })
    return intervals
  }

  //Note: return value == "does this interval include or can be updated to include the version"
  //  Remember the semvers are sorted low to high, so any given semver is always either in in the current interval
  //  or starting the next one.
  addToInterval(version: Semver): boolean {
    if (this.startInterval.compare(version) === 0) {
      return true
    }
    if (this.endInterval?.compare(version) === 0) {
      return true
    }

    switch (version.semverType) {
    case SemverType.PATH_AND_ABOVE:
      return this.handlePatchAndAbove(version)
    case SemverType.VERSION:
      return this.handleSingleVersion(version)
    case SemverType.WILDCARD:
      return this.handleWildcard(version)
    }
    return true
  }

  toString(): string {
    if (this.endInterval === null) {
      return this.startInterval.toString(true)
    }
    return `${this.startInterval.toString(true)} - ${this.endInterval.toString(true)}`
  }

  private handleSingleVersion(version: Semver): boolean {
    const endInterval = this.endInterval
    if (endInterval === null) {
      if (this.startInterval.major !== version.major) {
        return false
      }
      if (!this.sameMinorOrNextMinor(this.startInterval, version)) {
        return false // it is not in the same minor nor is in the next minor
      }
      switch (this.startInterval.semverType) {
      case SemverType.PATH_AND_ABOVE:
        if (!this.startInterval.sameMinor(version)) {
          if (version.patch !== '0') {
            return false
          }
          this.endInterval = version
          return true
        }
        if (this.startInterval.patch > version.patch) {
          return false
        }
        return true
      case SemverType.VERSION:
        if (this.startInterval.sameMinor(version)) {
          if (Number(this.startInterval.patch) + 1 !== Number(version.patch)) {
            return false
          }
        } else {
          return false
        }
        this.endInterval = version
        return true
      case SemverType.WILDCARD:
        if (!this.startInterval.sameMinor(version)) {
          if (version.patch === '0') {
            this.endInterval = version
            return true
          }
          return false
        } else {
          return true
        }
      }
      return false
    }

    if (endInterval.major !== version.major) {
      return false
    }

    if (!this.sameMinorOrNextMinor(endInterval, version)) {
      return false
    }
    switch (endInterval.semverType) {
    case SemverType.PATH_AND_ABOVE:
      // We assume that the only valid value for the end interval as to be ~>x.x.0
      if (endInterval.sameMinor(version)) {
        return true
      }
      if (version.patch === '0') {
        this.endInterval = version
        return true
      }
      return false
    case SemverType.VERSION:
      if (endInterval.sameMinor(version)) {
        if (Number(endInterval.patch) + 1 !== Number(version.patch)) {
          return false
        }
      } else {
        return false
      }
      this.endInterval = version
      return true
    case SemverType.WILDCARD:
      if (!endInterval.sameMinor(version)) {
        if (version.patch !== '0') {
          return false
        }
        this.endInterval = version
      }
      return true
    }
    return false
  }

  private sameMinorOrNextMinor(v1: Semver, v2: Semver) {
    return v1.minor === v2.minor || Number(v1.minor) + 1 === Number(v2.minor)
  }

  private handleWildcard(version: Semver): boolean {
    const endVersion = this.endInterval
    if (endVersion === null) {
      switch (this.startInterval.semverType) {
      case SemverType.PATH_AND_ABOVE:
        if(!this.sameMinorOrNextMinor(this.startInterval, version)) {
          return false
        }
        if(this.startInterval.sameMinor(version) && this.startInterval.patch === '0') {
          return true
        } else if(this.startInterval.sameMinor(version)) {
          return false
        }

        if(Number(this.startInterval.minor) + 1 === Number(version.minor)){
          this.endInterval = version
          return true
        }
        return false
      }
      if (this.startInterval.major === version.major && Number(this.startInterval.minor) + 1 === Number(version.minor)) {
        this.endInterval = version
        return true
      }
      return false
    }
    // Assumption we only add to the end of the interval
    if (endVersion.major !== version.major) {
      return false
    }
    switch (endVersion.semverType) {
    case SemverType.WILDCARD:
      if (this.sameMinorOrNextMinor(endVersion, version)) {
        this.endInterval = version
        return true
      }
      return false
    case SemverType.VERSION:
      if (endVersion.sameMinor(version)) {
        this.endInterval = version
        return true
      }
      return false
    case SemverType.PATH_AND_ABOVE:
      if(endVersion.sameMinor(version)) {
        return endVersion.patch === '0';

      }
      if( Number(endVersion.minor) + 1 === Number(version.minor)) {
        this.endInterval = version
        return true
      }
      return false
    }
  }

  private handlePatchAndAbove(version: Semver) {
    const endVersion = this.endInterval
    if (endVersion === null) {
      if (this.startInterval.major !== version.major) {
        return false
      }
      if(!this.sameMinorOrNextMinor(this.startInterval, version)) {
        return false
      }
      switch (this.startInterval.semverType) {
      case SemverType.WILDCARD:
      case SemverType.PATH_AND_ABOVE:
        if(this.startInterval.sameMinor(version)) {
          return this.startInterval.patch === version.patch
        }
        if(version.patch === '0') {
          this.endInterval = version
          return true
        }
        return false
      case SemverType.VERSION:
        return false
      }
      return false
    }

    if (endVersion.major !== version.major) {
      return false
    }
    if(!this.sameMinorOrNextMinor(endVersion, version)) {
      return false
    }
    switch (endVersion.semverType) {
    case SemverType.PATH_AND_ABOVE:
      if(endVersion.sameMinor(version)) {
        return endVersion.patch === version.patch
      }
      if(version.patch === '0') {
        this.endInterval = version
        return true
      }
      return false
    case SemverType.VERSION:
      return false
    case SemverType.WILDCARD:
      if(endVersion.sameMinor(version)) {
        return true
      }
      if(version.patch === '0') {
        this.endInterval = version
        return true
      }
      return false
    }
  }
}
