const opsmanVersions: { [key: string]: { releaseVersion: string } } = {
  '2.0-build.213': {'releaseVersion': '2.0.0'},
  '2.0-build.226': {'releaseVersion': '2.0.1'},
  '2.0-build.233': {'releaseVersion': '2.0.2'},
  '2.0-build.236': {'releaseVersion': '2.0.3'},
  '2.0-build.246': {'releaseVersion': '2.0.4'},
  '2.0-build.249': {'releaseVersion': '2.0.5'},
  '2.0-build.255': {'releaseVersion': '2.0.6'},
  '2.0-build.263': {'releaseVersion': '2.0.7'},
  '2.0-build.264': {'releaseVersion': '2.0.8'},
  '2.0-build.269': {'releaseVersion': '2.0.9'},
  '2.0-build.276': {'releaseVersion': '2.0.10'},
  '2.0-build.279': {'releaseVersion': '2.0.11'},
  '2.0-build.303': {'releaseVersion': '2.0.12'},
  '2.0-build.307': {'releaseVersion': '2.0.13'},
  '2.0-build.314': {'releaseVersion': '2.0.14'},
  '2.0-build.320': {'releaseVersion': '2.0.15'},
  '2.0-build.329': {'releaseVersion': '2.0.16'},
  '2.0-build.333': {'releaseVersion': '2.0.17'},
  '2.0-build.335': {'releaseVersion': '2.0.18'},
  '2.0-build.336': {'releaseVersion': '2.0.19'},
  '2.0-build.343': {'releaseVersion': '2.0.20'},
  '2.0-build.345': {'releaseVersion': '2.0.21'},
  '2.0-build.348': {'releaseVersion': '2.0.22'},
  '2.0-build.349': {'releaseVersion': '2.0.23'},
  '2.0-build.365': {'releaseVersion': '2.0.24'},
  '2.0-build.369': {'releaseVersion': '2.0.25'},
  '2.0-build.375': {'releaseVersion': '2.0.26'},
  '2.0-build.381': {'releaseVersion': '2.0.27'},
  '2.0-build.409': {'releaseVersion': '2.0.28'},
  '2.0-build.412': {'releaseVersion': '2.0.29'},
  '2.1-build.204': {'releaseVersion': '2.1.0'},
  '2.1-build.212': {'releaseVersion': '2.1.1'},
  '2.1-build.214': {'releaseVersion': '2.1.2'},
  '2.1-build.304': {'releaseVersion': '2.1.3'},
  '2.1-build.314': {'releaseVersion': '2.1.4'},
  '2.1-build.318': {'releaseVersion': '2.1.5'},
  '2.1-build.326': {'releaseVersion': '2.1.6'},
  '2.1-build.335': {'releaseVersion': '2.1.7'},
  '2.1-build.339': {'releaseVersion': '2.1.8'},
  '2.1-build.340': {'releaseVersion': '2.1.9'},
  '2.1-build.341': {'releaseVersion': '2.1.10'},
  '2.1-build.348': {'releaseVersion': '2.1.11'},
  '2.1-build.350': {'releaseVersion': '2.1.12'},
  '2.1-build.355': {'releaseVersion': '2.1.13'},
  '2.1-build.361': {'releaseVersion': '2.1.14'},
  '2.1-build.372': {'releaseVersion': '2.1.15'},
  '2.1-build.377': {'releaseVersion': '2.1.16'},
  '2.1-build.389': {'releaseVersion': '2.1.17'},
  '2.1-build.396': {'releaseVersion': '2.1.18'},
  '2.1-build.413': {'releaseVersion': '2.1.19'},
  '2.1-build.428': {'releaseVersion': '2.1.20'},
  '2.2-build.292': {'releaseVersion': '2.2.0'},
  '2.2-build.296': {'releaseVersion': '2.2.1'},
  '2.2-build.300': {'releaseVersion': '2.2.2'},
  '2.2-build.304': {'releaseVersion': '2.2.3'},
  '2.2-build.312': {'releaseVersion': '2.2.4'},
  '2.2-build.316': {'releaseVersion': '2.2.5'},
  '2.2-build.319': {'releaseVersion': '2.2.6'},
  '2.2-build.334': {'releaseVersion': '2.2.7'},
  '2.2-build.352': {'releaseVersion': '2.2.8'},
  '2.2-build.359': {'releaseVersion': '2.2.9'},
  '2.2-build.372': {'releaseVersion': '2.2.10'},
  '2.2-build.376': {'releaseVersion': '2.2.11'},
  '2.2-build.380': {'releaseVersion': '2.2.12'},
  '2.2-build.382': {'releaseVersion': '2.2.13'},
  '2.2-build.386': {'releaseVersion': '2.2.14'},
  '2.2-build 398': {'releaseVersion': '2.2.15'},
  '2.2-build.406': {'releaseVersion': '2.2.16'},
  '2.2-build.414': {'releaseVersion': '2.2.17'},
  '2.2-build.418': {'releaseVersion': '2.2.18'},
  '2.2-build.424': {'releaseVersion': '2.2.19'},
  '2.2-build.427': {'releaseVersion': '2.2.20'},
  '2.2-build.436': {'releaseVersion': '2.2.21'},
  '2.2-build.457': {'releaseVersion': '2.2.22'},
  '2.2-build.464': {'releaseVersion': '2.2.23'},
  '2.2-build.468': {'releaseVersion': '2.2.24'},
  '2.2-build.477': {'releaseVersion': '2.2.25'},
  '2.3-build.146': {'releaseVersion': '2.3.0'},
  '2.3-build.167': {'releaseVersion': '2.3.1'},
  '2.3-build.170': {'releaseVersion': '2.3.2'},
  '2.3-build.184': {'releaseVersion': '2.3.3'},
  '2.3-build.188': {'releaseVersion': '2.3.4'},
  '2.3-build.194': {'releaseVersion': '2.3.5'},
  '2.3-build.212': {'releaseVersion': '2.3.6'},
  '2.3-build.224': {'releaseVersion': '2.3.7'},
  '2.3-build.237': {'releaseVersion': '2.3.8'},
  '2.3-build.244': {'releaseVersion': '2.3.9'},
  '2.3-build.250': {'releaseVersion': '2.3.10'},
  '2.3-build.258': {'releaseVersion': '2.3.11'},
  '2.3-build.268': {'releaseVersion': '2.3.12'},
  '2.3-build.274': {'releaseVersion': '2.3.13'},
  '2.3-build.281': {'releaseVersion': '2.3.14'},
  '2.3-build.287': {'releaseVersion': '2.3.15'},
  '2.3-build.300': {'releaseVersion': '2.3.16'},
  '2.3-build.305': {'releaseVersion': '2.3.17'},
  '2.3-build.313': {'releaseVersion': '2.3.18'},
  '2.3-build.317': {'releaseVersion': '2.3.19'},
  '2.3-build.332': {'releaseVersion': '2.3.20'},
  '2.3-build.334': {'releaseVersion': '2.3.21'},
  '2.3-build.337': {'releaseVersion': '2.3.22'},
  '2.3-build.343': {'releaseVersion': '2.3.23'},
  '2.3-build.347': {'releaseVersion': '2.3.24'},
  '2.3-build.351': {'releaseVersion': '2.3.25'},
  '2.3-build.354': {'releaseVersion': '2.3.26'},
  '2.4-build.117': {'releaseVersion': '2.4.0'},
  '2.4-build.131': {'releaseVersion': '2.4.1'},
  '2.4-build.142': {'releaseVersion': '2.4.2'},
  '2.4-build.145': {'releaseVersion': '2.4.3'},
  '2.4-build.152': {'releaseVersion': '2.4.4'},
  '2.4-build.163': {'releaseVersion': '2.4.5'},
  '2.4-build.168': {'releaseVersion': '2.4.6'},
  '2.4-build.171': {'releaseVersion': '2.4.7'},
  '2.4-build.177': {'releaseVersion': '2.4.8'},
  '2.4-build.180': {'releaseVersion': '2.4.9'},
  '2.4-build.192': {'releaseVersion': '2.4.10'},
  '2.4-build.202': {'releaseVersion': '2.4.11'},
  '2.4-build.206': {'releaseVersion': '2.4.12'},
  '2.4-build.211': {'releaseVersion': '2.4.13'},
  '2.4-build.223': {'releaseVersion': '2.4.14'},
  '2.4-build.226': {'releaseVersion': '2.4.15'},
  '2.4-build.231': {'releaseVersion': '2.4.16'},
  '2.4-build.237': {'releaseVersion': '2.4.17'},
  '2.4-build.246': {'releaseVersion': '2.4.18'},
  '2.4-build.260': {'releaseVersion': '2.4.19'},
  '2.4-build.268': {'releaseVersion': '2.4.20'},
  '2.4-build.271': {'releaseVersion': '2.4.21'},
  '2.4-build.276': {'releaseVersion': '2.4.22'},
  '2.4-build.284': {'releaseVersion': '2.4.23'},
  '2.4-build.287': {'releaseVersion': '2.4.24'},
  '2.4-build.293': {'releaseVersion': '2.4.25'},
  '2.4-build.298': {'releaseVersion': '2.4.26'},
  '2.4-build.303': {'releaseVersion': '2.4.27'},
  '2.5.0-build.158': {'releaseVersion': '2.5.0'},
  '2.5.1-build.169': {'releaseVersion': '2.5.1'},
  '2.5.2-build.172': {'releaseVersion': '2.5.2'},
  '2.5.3-build.185': {'releaseVersion': '2.5.3'},
  '2.5.4-build.189': {'releaseVersion': '2.5.4'},
  '2.5.5-build.194': {'releaseVersion': '2.5.5'},
  '2.5.6-build.198': {'releaseVersion': '2.5.6'},
  '2.5.7-build.208': {'releaseVersion': '2.5.7'},
  '2.5.8-build.212': {'releaseVersion': '2.5.8'},
  '2.5.9-build.216': {'releaseVersion': '2.5.9'},
  '2.5.10-build.219': {'releaseVersion': '2.5.10'},
  '2.5.11-build.224': {'releaseVersion': '2.5.11'},
  '2.5.12-build.229': {'releaseVersion': '2.5.12'},
  '2.5.13-build.232': {'releaseVersion': '2.5.13'},
  '2.5.14-build.241': {'releaseVersion': '2.5.14'},
  '2.5.15-build.248': {'releaseVersion': '2.5.15'},
  '2.5.16-build.257': {'releaseVersion': '2.5.16'},
  '2.5.17-build.260': {'releaseVersion': '2.5.17'},
  '2.5.18-build.266': {'releaseVersion': '2.5.18'},
  '2.5.19-build.271': {'releaseVersion': '2.5.19'},
  '2.5.20-build.283': {'releaseVersion': '2.5.20'},
  '2.5.21-build.297': {'releaseVersion': '2.5.21'},
  '2.5.22-build.303': {'releaseVersion': '2.5.22'},
  '2.5.23-build.309': {'releaseVersion': '2.5.23'},
  '2.5.24-build.315': {'releaseVersion': '2.5.24'},
  '2.5.25-build.322': {'releaseVersion': '2.5.25'},
  '2.5.26-build.327': {'releaseVersion': '2.5.26'},
  '2.5.27-build.334': {'releaseVersion': '2.5.27'},
}

export default function lookupReleaseVersion(slug:string, version:string): string {
  if(slug === 'p-bosh' || slug === 'ops-manager') {
    if (opsmanVersions.hasOwnProperty(version)) {
      return opsmanVersions[version].releaseVersion
    }
  }

  return version
}
