import React from 'react'
import { connect } from 'react-redux'
import ProductRow from './product_row'
import DuplicateChosenProductsWarning from './duplicate_chosen_products_warning'
import { AffectedRelease, ErrorInfoState, PlanErrorTypes } from '../error_info/error_info_types'
import { alertToDropdownHighlights, ChosenProduct, PlanAlertLevels } from './chosen_products_types'
import { FlexCol, Grid } from 'pivotal-ui/react/flex-grids'
import { Icon } from 'pivotal-ui/react/iconography'
import { Checkbox } from 'pivotal-ui/react/checkbox'
import { TooltipTrigger } from 'pivotal-ui/react/tooltip'
import { UpgradePortalStore } from '../stores/store'
import { Alert } from '../repositories/upgrade_plan'
import { isOpsMan } from '../repositories/foundation'
import {
  selectMinimalServiceTileTargetVersions,
  updateMinimalTargetVersions
} from '../actions/select_minimal_service_tile_target_versions'


type ChosenProductsListProps = {
  chosenProducts: ChosenProduct[]
  errorInfo: ErrorInfoState
  alerts: Alert[]
  minimalServiceTileTargetVersionSelected: boolean
  updateMinimalTargetVersions: (_: ChosenProduct[]) => void
  selectMinimalServiceTileTargetVersions: (_: boolean) => void
}

const eogsDefinitionTooltip = (
  <TooltipTrigger
    clickHideDelay={3000}
    tooltip={<div className={'txt-c'}>
      <div>End of General Support</div>
      <div><a target='_blank' rel="noopener noreferrer" href="https://tanzu.vmware.com/support/lifecycle_policy">Learn
        More </a></div>
    </div>}
    trigger="click">
    <Icon src={'help'} style={{fill: '#0070EC'}} verticalAlign={'baseline'} className='pls'/>
  </TooltipTrigger>
)

const ChosenProductsList = ({chosenProducts, errorInfo, alerts, updateMinimalTargetVersions, selectMinimalServiceTileTargetVersions, minimalServiceTileTargetVersionSelected}: ChosenProductsListProps) => {
  const headerRow = (
    <Grid key={'header-row'} className={'border grid grid-show no-margin'}>
      <FlexCol fixed col={9} className={'ptxxl pbl plxl'}><strong>VMware Tanzu Platform Products</strong></FlexCol>
      <FlexCol fixed className={'col-3-5 ptxxl pbl txt-c'} id={'current-column-label'}><strong>Current
        Version</strong></FlexCol>
      <FlexCol fixed className={'col-3-5 ptxxl pbl txt-c'}>
        <strong>Current EOGS</strong>
        {eogsDefinitionTooltip}
      </FlexCol>
      <FlexCol fixed className={'col-3-5 ptxxl pbl txt-c'} id={'target-column-label'}><strong>Target
        Version</strong></FlexCol>
      <FlexCol fixed className={'col-3-5 ptxxl pbl txt-c'} id={'target-eogs-column-label'}>
        <strong>Target EOGS</strong>
        {eogsDefinitionTooltip}
      </FlexCol>
      <FlexCol fixed col={1}/>
    </Grid>
  )

  const minimizeTileProductUpgradesTooltip = (
    <div className={'ptm'}>
      <TooltipTrigger
        clickHideDelay={15000}
        tooltip={<div className={'txt-c'}>
          <div>
            This option selects the minimally compatible versions of every product tile and
          </div>
          <div>
            may generate a smaller upgrade plan at the cost of a reduced service window.
          </div>
        </div>}
        trigger="click">
        <Icon src={'help'} style={{fill: '#0070EC'}} verticalAlign={'baseline'} className='pls'/>
      </TooltipTrigger>
    </div>
  )

  const tilesHeaderRows = (
    <div key={'service-tile-header'} className={'ptxxxl'}>
      <Grid key={'options-service-tiles'} className={'grid-show no-margin plxl'}>
        <Checkbox
          key={'minimal-service-tile-checkbox'}
          checked={minimalServiceTileTargetVersionSelected}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            selectMinimalServiceTileTargetVersions(event.target.checked)
            if (event.target.checked) {
              updateMinimalTargetVersions(chosenProducts)
            }
          }
          }>
          Minimize Tile Product Upgrades</Checkbox>
        {minimizeTileProductUpgradesTooltip}
      </Grid>

      <div key={'service-tile-header-spacer'} className={'ptm'}>

      </div>
      <Grid key={'header-row'} className={'border grid grid-show no-margin'}>
        <FlexCol fixed col={9} className={'ptxxl pbl plxl'}><strong>Tile Products</strong></FlexCol>
        <FlexCol fixed className={'col-3-5 ptxxl pbl txt-c'} id={'current-column-label'}><strong>Current
          Version</strong></FlexCol>
        <FlexCol fixed className={'col-3-5 ptxxl pbl txt-c'}>
          <strong>Current EOGS</strong>
          {eogsDefinitionTooltip}
        </FlexCol>
        <FlexCol fixed className={'col-3-5 ptxxl pbl txt-c'} id={'target-column-label'}><strong>Target Version</strong></FlexCol>
        <FlexCol fixed className={'col-3-5 ptxxl pbl txt-c'} id={'target-eogs-column-label'}>
          <strong>Target EOGS</strong>
          {eogsDefinitionTooltip}
        </FlexCol>
        <FlexCol fixed col={1}/>
      </Grid>
    </div>
  )

  let pivotalPlatformProducts = chosenProducts
    .filter((product: ChosenProduct) => product.pivotalPlatform && !product.isFetching)
    .sort(sortProducts)
  let otherTiles = chosenProducts.filter((product: ChosenProduct) => !product.pivotalPlatform && !product.isFetching).sort(sortProducts)
  let stillFetchingProducts = chosenProducts.filter((product: ChosenProduct) => product.isFetching).sort(sortProducts)

  let stillFetchingRows = createProductRows(alerts, errorInfo, stillFetchingProducts)
  let pivotalPlatformProductRows = [headerRow].concat(createProductRows(alerts, errorInfo, pivotalPlatformProducts))

  let otherProductRows = createProductRows(alerts, errorInfo, otherTiles)
  if (otherProductRows.length > 0) {
    otherProductRows = [tilesHeaderRows].concat(otherProductRows)
  }

  return (
    <div>
      <h2 className='inline pbl'>Review Product Current and Target Version</h2>
      <p className='pbl'>Recommended target versions of platform and service tiles are compatible with the Operations Manager Target Version selected above.</p>

      <DuplicateChosenProductsWarning chosenProducts={chosenProducts}/>
      {pivotalPlatformProductRows}
      {otherProductRows}
      {stillFetchingRows}
    </div>
  )
}

function isPas(slug: string) {
  return slug === 'elastic-runtime' || slug === 'cf'
}

function sortProducts(product: ChosenProduct, otherProduct: ChosenProduct) {
  let slugA = product.slug.toLowerCase()
  let slugB = otherProduct.slug.toLowerCase()

  if (isOpsMan(slugA)) {
    return -1
  } else if (isPas(slugA)) {
    if (isOpsMan(slugB)) {
      return 1
    } else {
      return -1
    }
  } else {
    return 0
  }
}

function createProductRows(alerts: Alert[], errorInfo: ErrorInfoState, sortedProducts: ChosenProduct[]) {
  return sortedProducts.map((p, index) => {
    const current = p.current
    const target = p.target

    const currentError = productRowErrorType(alerts, errorInfo, p, current, true)
    const targetError = productRowErrorType(alerts, errorInfo, p, target, false)

    return (
      <ProductRow key={`${p.slug}-${index}`} chosenProduct={p} releases={p.releases}
        errors={{current: currentError, target: targetError}}/>
    )
  })
}

function productRowErrorType(alerts: Alert[], errorInfo: ErrorInfoState, product: ChosenProduct, version: string | null, isCurrent: boolean) {
  let isError = shouldDisplayError(errorInfo, product, version, isCurrent)
  let warningType = calcWarningType(alerts, product, isCurrent)

  if (isError) return PlanAlertLevels.ERROR
  else return warningType
}

function shouldDisplayError(errorInfo: ErrorInfoState, product: ChosenProduct, version: string | null, isCurrent: boolean) {
  if (errorInfo === undefined || (errorInfo.type !== PlanErrorTypes.INCONSISTENT_STATE_CURRENT && errorInfo.type !== PlanErrorTypes.INCONSISTENT_STATE_TARGET)) {
    return false
  }

  return errorInfo.affected.some((val: AffectedRelease) => {
    let containsVersion = product.slug === val.slug && val.version === version
    if (!containsVersion) return false

    return (isCurrent && errorInfo.type === PlanErrorTypes.INCONSISTENT_STATE_CURRENT) ||
      (!isCurrent && errorInfo.type === PlanErrorTypes.INCONSISTENT_STATE_TARGET)
  })
}

function calcWarningType(alerts: Alert[], product: ChosenProduct, isCurrent: boolean) {
  let type = alerts.reduce((acc: PlanAlertLevels | null, alert: Alert) => {
    if (alert.name !== product.name) return acc

    if (isCurrent && alert.currentVersion !== product.current) return acc

    let highlights = alertToDropdownHighlights[alert.type]
    if (!highlights) return acc

    return (acc || isCurrent ? highlights.currentType : highlights.targetType)
  }, null)

  return type ? type : PlanAlertLevels.NONE
}

export { ChosenProductsList }

const mapStateToProps = (state: UpgradePortalStore) => {
  return {
    chosenProducts: state.chosenProducts,
    errorInfo: state.errorInfo,
    alerts: state.upgradePlan.alerts,
    minimalServiceTileTargetVersionSelected: state.selectMinimalServiceTileTargetVersions
  }
}

const mapDispatchToProps = {
  updateMinimalTargetVersions,
  selectMinimalServiceTileTargetVersions
}
export default connect(mapStateToProps, mapDispatchToProps)(ChosenProductsList)
