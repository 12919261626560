import React, {ReactElement} from 'react'
import {InfoStep, Plan, ProductPlan} from '../../repositories/upgrade_plan'
import {Table, Tbody, Td, Th, Thead, Tr} from 'pivotal-ui/react/table'
import {PlanStepTypes} from './upgrade_steps_types'
import {HEALTHWATCH_SLUG, MYSQL_V1_SLUG} from '../../constants/constants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

type PlanOverviewProps = {
  upgradePlan: Plan
}

export const PlanOverview = ({upgradePlan}: PlanOverviewProps) => {
  const overviewTable = toOverviewTable(upgradePlan)

  let headers = createTableHeaders(upgradePlan)

  let lines = createOverviewTable(overviewTable, upgradePlan)

  return (
    <div className={'plan-overview mbxl'}>
      <h3>Plan Overview</h3>
      <div className={'inner mtxl'}>
        <Table className={'pui-table--tr-hover pui-table--borders-none'}>
          <Thead>
            <Tr>
              {headers}
            </Tr>
          </Thead>
          <Tbody>
            {lines}
          </Tbody>
        </Table>
      </div>
    </div>
  )
}

function createTableHeaders(upgradePlan: Plan) {
  let headers = []
  headers.push(<Th key={'header-product-name'} className={'fixed'}>Tanzu Platform Products</Th>)
  headers.push(<Th key={'header-current-version'}>Current Version</Th>)
  upgradePlan.phases.forEach((_, idx) => {
    headers.push(<Th key={`header-phase-${idx}`}>Phase {idx + 1}</Th>)
  })
  headers.push(<Th key={'header-target-version'}>Target Version</Th>)
  return headers
}

function createOverviewTable(overviewTable: ProductVersions[], upgradePlan: Plan) {
  let lines: ReactElement[] = []
  overviewTable.forEach((product) => {
    let productLine = []
    productLine.push((
      <Th scope={'row'} key={`overview-product-${product.slug}-name`} id={`overview-product-${product.slug}-name`}
        className={'fixed'}>{product.name}</Th>))
    productLine.push((<Td key={`overview-product-${product.slug}-current-version`}
      id={`overview-product-${product.slug}-current-version`}>{product.current}</Td>))
    upgradePlan.phases.forEach((_, idx) => {
      productLine.push((
        <Td key={`overview-phase-${idx}`} id={`overview-phase-${idx}`}>{product.phases[idx]}</Td>
      ))
    })
    productLine.push((<Td key={`overview-product-${product.slug}-target-version`}
      id={`overview-product-${product.slug}-target-version`}>{product.target}</Td>))
    lines.push(<Tr key={`overview-product-${product.slug}`} id={`overview-product-${product.slug}`}>{productLine}</Tr>)
  })
  return lines
}

type ProductVersions = {
  slug: string
  name: string
  current: string
  target: string
  phases: Record<number, string | ReactElement>
}

function isMysqlV1MigrationStep(step: InfoStep) {
  return step.value.text === 'Migrating Data from MySQL for PCF v1 to v2'
}

function sortProducts(a: ProductPlan, b: ProductPlan): number {
  if (a.slug === 'ops-manager') {
    return -1
  } else if (b.slug === 'ops-manager') {
    return 1
  } else if (a.pivotalPlatform && !b.pivotalPlatform) {
    return -1
  } else if (!a.pivotalPlatform && b.pivotalPlatform) {
    return 1
  }
  return a.name.localeCompare(b.name)
}

function toOverviewTable(upgradePlan: Plan): ProductVersions[] {
  let productsVersions: ProductVersions[] = []
  upgradePlan.foundation.sort(sortProducts).forEach((productPlan) => {
    let current = productPlan.current || 'N/A'
    if (!productPlan.presentInInitialFoundation) {
      current = 'N/A'
    }

    productsVersions.push({
      slug: productPlan.slug,
      name: productPlan.name || 'Not Present',
      current: current,
      target: productPlan.target || 'End of Life',
      phases: {}
    })
  })

  let afterMysqlMigration = false
  let deletedSlugs: Array<string> = []
  upgradePlan.phases.forEach((phase, idx) => {
    if (afterMysqlMigration) {
      let product = productsVersions.find((product) => product.slug === MYSQL_V1_SLUG)
      product!.phases[idx] = <div className={'txt-c'}><FontAwesomeIcon icon={'ban'}/></div>
    }

    deletedSlugs.forEach((slug) => {
      let product = productsVersions.find((product) => product.slug === slug)
      product!.phases[idx] = <div className={'txt-c'}><FontAwesomeIcon icon={'ban'}/></div>
    })

    phase.forEach((step) => {
      let product
      switch (step.type) {
      case PlanStepTypes.RELEASE:
        product = productsVersions.find((product) => product.slug === step.value.slug)
        if (product !== null) {
          product!.phases[idx] = step.value.version
        }
        return
      case PlanStepTypes.DELETE:
        product = productsVersions.find((product) => product.slug === step.value.slug)
        if (product !== null) {
          product!.phases[idx] = <a href={step.value.link} target={'_new'}>Delete {step.value.name} {step.value.version}</a>
          if (step.value.slug !== HEALTHWATCH_SLUG) deletedSlugs.push(step.value.slug)
        }
        return
      case PlanStepTypes.INFO:
        if (isMysqlV1MigrationStep(step)) {
          afterMysqlMigration = true
          let product = productsVersions.find((product) => product.slug === MYSQL_V1_SLUG)
          if (product !== null) {
            product!.phases[idx] = <a href={step.value.link} target={'_new'}>Migrate Data to V2</a>
          }
        }
      }
    })
  })

  deletedSlugs.forEach((slug) => {
    let product = productsVersions.find((product) => product.slug === slug)
    product!.target = 'Deleted'
  })

  return productsVersions
}
