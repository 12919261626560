import { ProductDetails } from './products'
import { Semver } from '../services/semver'
import { UPServerFetcher } from '../services/up_server_fetcher'

export enum OpsManagerVersion {
  N = "3.0.*",
  N_1 = '2.10.*',
  N_2 = '2.9.*',
  N_3 = '2.8.*',
  N_4 = '2.7.*',
  N_5 = '2.6.*',
  N_6 = '2.5.*',
  N_7 = '2.4.*',
  N_8 = '2.3.*',
  NO_VERSION = ''
}

export function toSemver(version: OpsManagerVersion): Semver {
  return Semver.fromString(version)
}

export function versionName(version: OpsManagerVersion): string {
  return `v${version.split('.').slice(0, 2).join('.')}`
}

export function toOpsManagerVersion(version: string): OpsManagerVersion {
  let a = Semver.fromString(version)
  let opsManagerVersion = Object.values(OpsManagerVersion).find((version) => a.sameMinor(Semver.fromString((version.toString()))))
  if (opsManagerVersion === undefined) {
    return OpsManagerVersion.N
  }
  return opsManagerVersion as OpsManagerVersion
}

export function isOpsMan(slug: string) {
  return slug === 'ops-manager' || slug === 'p-bosh'
}

export type RecommendedVersion = {
  slug: string
  target: Semver
}

export type Foundation = {
  products: ProductDetails[]
  recommendedVersions: RecommendedVersion[]
  targetOpsManagerMinorVersion: OpsManagerVersion
  canRecommend: boolean
}

type JsonRecommendedVersions = {
  slug: string
  target: string
}

type JsonFoundation = {
  products: ProductDetails[]
  recommendedTargetVersions: JsonRecommendedVersions[]
}

export type DetailsFoundationRequest = DetailsFoundationProductRequest[]

export type DetailsFoundationProductRequest = {
  slug: string,
  current: string
}

export interface FoundationRepository {
  getFoundationDetails(listOfSlugs: DetailsFoundationRequest): Promise<Foundation>
}

export class FoundationRepositoryImpl implements FoundationRepository {
  private upServerFetcher: UPServerFetcher

  constructor(upServerFetcher: UPServerFetcher) {
    this.upServerFetcher = upServerFetcher
  }

  getFoundationDetails(listOfSlugs: DetailsFoundationRequest): Promise<Foundation> {
    const request = this.processDiagnosticReportRequest(listOfSlugs)

    return this.upServerFetcher.fetch(request)
      .then((response: Response): JsonFoundation | any => {
        if (response.status > 399) {
          return Promise.reject(response)
        } else return response.json()
      }).then((jsonObject: JsonFoundation): Foundation => {
        let opsmanRecommendation = jsonObject.recommendedTargetVersions.find((p) => isOpsMan(p.slug))
        let minor = OpsManagerVersion.N
        if (opsmanRecommendation !== undefined) {
          minor = toOpsManagerVersion(opsmanRecommendation.target)
        }
        return {
          products: jsonObject.products,
          recommendedVersions: jsonObject.recommendedTargetVersions.map((recommendation) => {
            return {
              slug: recommendation.slug,
              target: Semver.fromString(recommendation.target)
            }
          }),
          targetOpsManagerMinorVersion: minor,
          canRecommend: jsonObject.recommendedTargetVersions.length !== 0
        }
      }).then((foundation) => {
        foundation.products.forEach((product) => {
          product.releases.forEach((release) => {
            release.isCompatible = true
          })
        })
        return foundation
      }).catch((e) => {
        return Promise.reject(e)
      })
  }

  private processDiagnosticReportRequest(listOfSlugs: DetailsFoundationRequest) {
    const body = JSON.stringify({products: listOfSlugs})

    const options: RequestInit = {
      method: 'POST',
      headers: this.makeHeaders(),
      mode: 'cors',
      cache: 'default',
      body
    }

    const url = this.upServerFetcher.generateUrl('/v1/process_diagnostic_report')
    const request = new Request(
      url,
      options
    )

    return request
  }

  private makeHeaders() {
    const requestHeaders = new Headers()

    requestHeaders.append('Accept', 'application/json')
    requestHeaders.append('Content-Type', 'application/json')

    return requestHeaders
  }
}
