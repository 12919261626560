import React, { ReactNode } from 'react'
import { TooltipTrigger } from 'pivotal-ui/react/tooltip'
import { PrimaryButton } from 'pivotal-ui/react/buttons'
import ButtonDownloadPdf from './button_download_pdf'
import { UpgradePortalStore } from '../stores/store'
import { connect } from 'react-redux'
import ButtonCopyPlan from './button_copy_plan'
import ButtonCopyPlanUrl from './button_copy_plan_url'
import { Dropdown } from 'pivotal-ui/react/dropdowns'
import AnalyticsWrapper from '../services/analytics_wrapper'
import { PlanRepository } from '../repositories/upgrade_plan'
import { downloadPdf } from '../actions/download_pdf'


type DropdownExportPlanProps = {
  analyticsWrapper: AnalyticsWrapper
  upgradePlanRepository: PlanRepository
  disablePdfDownloadButton: boolean
}

type DropdownExportPlanState = {
  tooltipOpen: boolean
  tooltipText: string
}

export class DropdownExportPlan extends React.Component<DropdownExportPlanProps, DropdownExportPlanState> {
  constructor(props: DropdownExportPlanProps) {
    super(props)

    this.state = {
      tooltipOpen: false,
      tooltipText: '',
    }
  }

  render(): ReactNode {
    if (this.props.disablePdfDownloadButton) {
      return <TooltipTrigger trigger='manual' display={true} tooltip={'Plan downloading...'}>
        <PrimaryButton disabled>Export Plan...</PrimaryButton>
      </TooltipTrigger>
    } else {
      return <TooltipTrigger trigger="manual" placement="top" display={this.state.tooltipOpen}
        tooltip={this.state.tooltipText}>
        <Dropdown title="Export Plan..." flat id={'export-plan'} showIcon={false}
          disabled={!this.props.disablePdfDownloadButton}>
          <div onClick={() => {
            this.setState({tooltipOpen: true, tooltipText: 'Plan copied to the clipboard'})
            setTimeout(() => this.setState({tooltipOpen: false}), 5000)
          }}>
            <ButtonCopyPlan analyticsWrapper={this.props.analyticsWrapper}/>
          </div>
          <div onClick={() => {
            this.setState({tooltipOpen: true, tooltipText: 'Plan URL copied to the clipboard'})
            setTimeout(() => this.setState({tooltipOpen: false}), 5000)
          }}>
            <ButtonCopyPlanUrl analyticsWrapper={this.props.analyticsWrapper}/>
          </div>
          <ButtonDownloadPdf
            downloadPdf={downloadPdf}
            analyticsWrapper={this.props.analyticsWrapper}
            upgradePlanRepository={this.props.upgradePlanRepository}
          />
        </Dropdown>
      </TooltipTrigger>
    }
  }
}

const mapStateToProps = ({disablePdfDownloadButton, upgradePlan}: UpgradePortalStore) => {
  return {disablePdfDownloadButton, upgradePlan}
}

export default connect(mapStateToProps)(DropdownExportPlan)
