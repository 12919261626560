import { DISABLE_DOWNLOAD_PDF_BUTTON, ENABLE_DOWNLOAD_PDF_BUTTON } from './actions'
import { Dispatch } from 'redux'

export type DisableDownloadPdfButtonAction = {
  type: typeof DISABLE_DOWNLOAD_PDF_BUTTON
}

export type EnableDownloadPdfButtonAction = {
  type: typeof ENABLE_DOWNLOAD_PDF_BUTTON
}

export type DownloadPdfButtonAction = DisableDownloadPdfButtonAction | EnableDownloadPdfButtonAction

export function disableDownloadPdfButtonAction(): DisableDownloadPdfButtonAction {
  return {
    type: DISABLE_DOWNLOAD_PDF_BUTTON
  }
}

export function enableDownloadPdfButtonAction(): EnableDownloadPdfButtonAction {
  return {
    type: ENABLE_DOWNLOAD_PDF_BUTTON
  }
}

export function disablePdfButton(): (dispatch: Dispatch) => void {
  const disablePdfButtonThunk = (dispatch: Dispatch) => {
    dispatch(disableDownloadPdfButtonAction())
  }

  return disablePdfButtonThunk
}

export function enablePdfButton(): (dispatch: Dispatch) => void {
  const enablePdfButtonThunk = (dispatch: Dispatch) => {
    dispatch(enableDownloadPdfButtonAction())
  }

  return enablePdfButtonThunk
}
