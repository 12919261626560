// TODO by claire on 7/24/2024: we had to temporarily remove all the Google Analytics calls because they were crashing
//                              TitanTAS for unknown reasons.  TitanTAS is unable to debug this due to lack of resource
//                              right now, so we're forced to keep this code commented out until they can determine the
//                              root cause and fix it. Once they fix the blank screen issue, we should uncomment this
//                              code.  See this GChat thread for full details: https://chat.google.com/room/AAAAOzvwFbo/s1X6Ha-s05k/s1X6Ha-s05k?cls=10
// import TagManager from 'react-gtm-module'

export default class AnalyticsWrapper {
  readonly code: string

  constructor(code: string) {
    this.code = code
  }

  initialize() {
    // const tagManagerArgs = {
    //   gtmId: this.code
    // }
    // TagManager.initialize(tagManagerArgs)
  }

  fireEvent(category: string, action: string, label: string, otherDimensions: Object = {}) {
    // const dataLayer = Object.assign(
    //   {
    //     eventCategory: category,
    //     event: 'ga-event',
    //     eventAction: action,
    //     eventLabel: label,
    //   },
    //   otherDimensions
    // )
    //
    // TagManager.dataLayer({
    //   dataLayer: dataLayer
    // })
  }

  firePageView(path: string) {
    // this.fireEvent('pageView', 'load', path)
  }
}
